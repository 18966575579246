import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Tooltip from "@mui/material/Tooltip";
import {
  disableScheduledNotification,
  getScheduledNotifications,
} from "../Api/ScheduledNotificationsApi";
import SearchIcon from "@mui/icons-material/Search";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { SCHEDULED_NOTIFICATIONS_COLUMN_PROPERTIES_LIST } from "../Utils/constants";
import ConfirmationPopup from "../Components/Popups/ConfirmationPopup";
import { TextField } from "@mui/material";
import { auditLogEmails } from "../Api/AuditLogEmails.Api";
import { ADMIN_USERS } from "../Utils/constants";
import { payloadEmail } from "../Utils/auditLogUtils";
import { formatDatetime } from "../Utils/BaseUtils";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function ScheduledNotifications() {
  const [trackingId, setTrackingId] = useState("");
  const [existingConfigs, setExistingConfigs] = useState([]);
  const [showResponse, setShowResponse] = useState(false);
  const [isDisableButtonLoading, setIsDisableButtonLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [disableConfig, setDisableConfig] = useState({});
  const [environment, setEnvironment] = useState("prod");

  const handleChange = (formVariable, event) => {
    switch (formVariable) {
      case "trackingId":
        setShowResponse(false);
        setTrackingId(event.target.value);
        break;
      default:
        break;
    }
  };

  const getColumnHeader = (minWidth, label) => {
    return (
      <TableCell align="left" style={{ minWidth: minWidth }}>
        <strong>{label}</strong>
      </TableCell>
    );
  };

  const getColumnHeaders = () => {
    const list = [];
    SCHEDULED_NOTIFICATIONS_COLUMN_PROPERTIES_LIST.forEach((item) => {
      list.push(getColumnHeader(item.minWidth, item.label));
    });
    return list;
  };

  const handleDisable = async () => {
    setIsDisableButtonLoading(true);
    const response = await disableScheduledNotification(
      disableConfig["requestId"],
      environment
    );
    setIsDisableButtonLoading(false);
    await reloadData();
    closeDeleteConfirmationPopUp();

    if (response !== undefined && response.status === 200) {
      // so send email to admins as someone disabled the scheduled notification.
      await auditLogEmails(
        "A Scheduled notification ",
        "disabled ",
        disableConfig,
        {}
      );
    }
  };

  function closeDeleteConfirmationPopUp() {
    setShowDeleteConfirmation(false);
  }

  function showDeleteConfirmationPopUp(event, config) {
    if (event !== undefined && event !== null) {
      event.preventDefault();
      setShowDeleteConfirmation(true);
    }

    if (config !== undefined && config !== null) {
      setDisableConfig(config);
    }

    return (
      <ConfirmationPopup
        confirmation={showDeleteConfirmation}
        closeConfirmationPopUp={closeDeleteConfirmationPopUp}
        handleAction={handleDisable}
        isLoading={isDisableButtonLoading}
        action={["disable", "disabling"]}
      />
    );
  }

  const getEntriesInTable = () => {
    const arr = [];

    function getActionColumnContent(config) {
      return (
        <TableCell>
          <Button
            className="mb-3"
            variant="outlined"
            color="warning"
            disabled={!ADMIN_USERS.includes(payloadEmail)}
            style={{ minWidth: "81px" }}
            size="sm"
            onClick={(event) => showDeleteConfirmationPopUp(event, config)}
          >
            Disable
          </Button>
        </TableCell>
      );
    }

    for (const index in existingConfigs) {
      const config = existingConfigs[index];
      arr.push(
        <TableRow id={config["id"]} key={config["id"]}>
          {SCHEDULED_NOTIFICATIONS_COLUMN_PROPERTIES_LIST.map((item) => {
            if (item.label === "Actions") return getActionColumnContent(config);
            return (
              <TableCell key={item.column}>
                {" "}
                {config[item.column] === null ||
                config[item.column] === undefined
                  ? null
                  : item.column !== "deliveryTime"
                  ? config[item.column].toString()
                  : formatDatetime(config[item.column])}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
    return arr;
  };

  const createTable = () => {
    return (
      <Table
        responsive
        striped
        bordered
        hover
        style={{ backgroundColor: "white" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>{getColumnHeaders()}</TableRow>
        </TableHead>
        <TableBody>{getEntriesInTable()}</TableBody>
      </Table>
    );
  };

  const handleEnvironmentChange = (event, newValue) => {
    setEnvironment(newValue);
  };

  async function reloadData() {
    setExistingConfigs([]);
    const response = await getScheduledNotifications({
      trackingId,
      environment,
    });

    if (response !== undefined) {
      setExistingConfigs(response.data);
    }

    setShowResponse(true);
  }

  return (
    <>
      <Container style={{ paddingTop: "150px", paddingLeft: "240px" }}>
        <Row>
          <Col md={1}></Col>
          <Col
            md={3}
            style={{
              marginTop: "6px",
              marginLeft: "-31px",
              marginRight: "30px",
            }}
          >
            <TextField
              inputProps={{ "data-testid": "trackingId" }}
              style={{ width: "100%" }}
              id="filled-message"
              label="Enter Tracking Id"
              value={trackingId}
              onChange={(e) => handleChange("trackingId", e)}
              InputLabelProps={{
                style: { color: "black" },
              }}
              variant="outlined"
            />
          </Col>

          <Col md={1} style={{ paddingTop: "18px" }}>
            <FormLabel
              data-testid="environment"
              id="demo-row-radio-buttons-group-label"
              style={{ color: "black", fontSize: "17px" }}
            >
              Environment
            </FormLabel>
          </Col>
          <Col md={3} style={{ paddingTop: "11px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleEnvironmentChange}
              defaultValue={"prod"}
              style={{ marginLeft: "15px" }}
            >
              <FormControlLabel
                value="prod"
                style={{
                  color: "grey",
                  fontSize: "17px",
                }}
                control={<Radio sx={{ color: "primary.light" }} />}
                label="Production"
                data-testid="prod-radio"
              />
              <FormControlLabel
                value="stage"
                style={{
                  color: "grey",
                  fontSize: "17px",
                }}
                control={<Radio sx={{ color: "primary.light" }} />}
                label="Stage"
                data-testid="stage-radio"
              />
            </RadioGroup>
          </Col>

          <Col md={1} style={{ paddingTop: "20px", marginLeft: "-31px" }}>
            <Tooltip title={"track notifications"} placement="right">
              <Button
                data-testid="searchIcon"
                variant="variant"
                startIcon={<SearchIcon />}
                onClick={reloadData}
              />
            </Tooltip>
          </Col>
        </Row>
      </Container>

      {existingConfigs !== undefined && (
        <Container style={{ paddingTop: "50px", paddingLeft: "140px" }}>
          {createTable()}
        </Container>
      )}

      {showResponse &&
        existingConfigs !== undefined &&
        existingConfigs.length === 0 && (
          <Container style={{ paddingTop: "50px", paddingLeft: "140px" }}>
            {<div>No scheduled notifications with this trackingId</div>}
          </Container>
        )}

      {showDeleteConfirmationPopUp(null, null)}
    </>
  );
}

export default ScheduledNotifications;
