import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Tooltip from "@mui/material/Tooltip";
import { COUNTRIES } from "../Utils/constants";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import MessageIcon from "@mui/icons-material/Message";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Divider from "@mui/material/Divider";
import { MenuItem, TextField } from "@mui/material";
import AddTemplatePopup from "../Components/AddTemplatePopup";
import { default as CustomTooltip } from "@mui/material/Tooltip";
import { v4 as uuidv4 } from "uuid";
import AddPhoneTemplatePopup from "../Components/AddPhoneTemplatePopup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import dayjs from "dayjs";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import BulkCSVUpload from "../Components/BulkCSVUpload";
import { nanoid } from "@reduxjs/toolkit";
import { ThemeProvider } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { sendNotification } from "../Api/SendNotificationApi";
import { ToastContainer, Toast } from "react-bootstrap";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import AlertDialog from "../Components/Popups/DialogBox";
import { customTheme } from "../Utils/BaseUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SendNotificationForm() {
  const [serviceProvider, setServiceProvider] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [country, setCountry] = useState("IN");
  const [receiver, setReceiver] = useState("");
  const [emailReceiver, setEmailReceiver] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [text, setText] = useState("");
  const [trackingId, setTrackingId] = useState(uuidv4());
  const [locale, setLocale] = useState("en");
  const [show, setShow] = useState(false);
  const [channel, setChannel] = useState("sms");
  const [emailSender, setEmailSender] = useState("");
  const [emailSenderName, setEmailSenderName] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [environment, setEnvironment] = useState("prod");
  const [addTemplatePopupVis, setAddTemplatePopupVis] = useState(false);
  const [addRecieverTemplatePopupVis, setAddRecieverTemplatePopupVis] =
    useState(false);
  const [templateVar, setTemplateVar] = useState({});
  const [serviceName, setServiceName] = useState("otp");
  const [receiverVar, setReceiverVar] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [bulkCSVVis, setBulkCSVVis] = useState(false);
  const [uploadedCSV, setUploadedCSV] = useState(false);
  const [storeCallbackReport, setStoreCallbackReport] = useState(false);
  const [unsubscriptionFlag, setUnsubscriptionFlag] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastSuccessMessage, setToastSuccessMessage] = useState(undefined);
  const [toastErrorMessage, setToastErrorMessage] = useState(undefined);
  const [lastTrackingIdToDisplay, setLastTrackingIdToDisplay] = useState("");
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [isSubmitNotificationLoader, setIsSubmitNotificationLoader] =
    useState(false);

  const responsePopupDisplayTime =
    process.env.REACT_APP_POPUP_DISPLAY_TIME_IN_MILLISEC;

  useEffect(() => {
    const defaultDate = new Date();
    defaultDate.setTime(defaultDate.getTime());
    const formattedStartTime = dayjs(defaultDate);
    setStartTime(formattedStartTime);
  }, []);

  const BulkCSVHandler = () => {
    setBulkCSVVis((state) => !state);
  };

  const BulkCSVUploadHandler = (data) => {
    let receiverVarTemp = [];
    data.forEach((dataItem) => {
      let JSONEle = {};
      let varList = [];
      Object.keys(dataItem).forEach((keyData) => {
        if (keyData === "Phone Number") {
          JSONEle["reciever"] = dataItem[keyData];
        } else if (keyData === "Country Code") {
          JSONEle["countryCode"] = dataItem[keyData];
        } else if (keyData === "Email") {
          JSONEle["reciever"] = dataItem[keyData];
        } else if (keyData === "Premium User Id") {
          JSONEle["premiumUserId"] = dataItem[keyData];
        } else if (keyData === "Free User Id") {
          JSONEle["freeUserId"] = dataItem[keyData];
        } else {
          varList.push({
            key: keyData,
            value: dataItem[keyData],
            id: nanoid(),
          });
        }
      });
      JSONEle["varList"] = varList;
      if (channel !== "email") {
        if (JSONEle["reciever"] !== undefined) {
          JSONEle["reciever"] = JSONEle["countryCode"] + JSONEle["reciever"];
          JSONEle["countryCode"] = "+" + JSONEle["countryCode"];
          let country = "";
          COUNTRIES.forEach((item) => {
            if (item["mobileCode"] === JSONEle["countryCode"]) {
              country = item["code"].toLowerCase();
            }
          });
          JSONEle["country"] = country;
        }
      }
      receiverVarTemp.push(JSONEle);
    });

    setReceiverVar(receiverVarTemp);
    setUploadedCSV(true);
    addRecieverTemplatePopupHandler();
  };

  const handleServiceChange = (event, newValue) => {
    setReceiver("+91");
    setCountry("");
    setCountryCode("");
    setServiceName(newValue);
    setServiceProvider([]);
    setStoreCallbackReport(false);
    setShowToast(false);
    setLastTrackingIdToDisplay("");
  };

  const handleSwitchChange = (event) => {
    setStoreCallbackReport(event.target.checked);
  };

  const handleUnsubscriptionSwitchChange = (event) => {
    setUnsubscriptionFlag(event.target.checked);
  };

  const addTemplatePopupHandler = () => {
    setAddTemplatePopupVis((state) => !state);
  };

  const addRecieverTemplatePopupHandler = () => {
    setAddRecieverTemplatePopupVis((state) => !state);
  };

  const handleLocaleChange = (event, newValue) => {
    setLocale(newValue);
  };

  const updateTemplateVarHandler = (data) => {
    setTemplateVar(data);
  };

  const updateRecieverVarList = (newRecieverVar) => {
    setReceiverVar(newRecieverVar);
  };

  const jsonElement = JSON.stringify(
    templateVar,
    null,
    Object.keys(templateVar).length
  );

  const handleChannelChange = (event, newValue) => {
    setServiceProvider((serviceProvider) => []);
    setTemplateVar({});
    setReceiver("+91");
    setText("");
    setTemplateId("");
    setChannel(newValue);
    setReceiverVar([]);
    setUploadedCSV(false);
    setLastTrackingIdToDisplay("");
    setUnsubscriptionFlag(false);
  };

  const handleEnvironmentChange = (event, newValue) => {
    setEnvironment(newValue);
  };

  const smsProviders = ["gupshup", "value_first", "airtel", "plivo"];
  const ivrProviders = ["value_first", "plivo"];
  const whatsappProviders = ["gupshup"];
  const emailProviders = ["sendgrid", "ses"];

  const handleClose = (event) => {
    setShow(false);
  };

  const handleShow = (event) => {
    event.preventDefault();
    setShow(true);
  };

  const handleSubmit = async (event) => {
    const chunkSize = process.env.REACT_APP_REQUEST_CHUNK_SIZE;
    const scheduledAfterTime = process.env.REACT_APP_SCHEDULE_AFTER_TIME;
    var numChunks = Math.ceil(receiverVar.length / chunkSize);
    if (serviceName === "otp") {
      numChunks = 1;
    }
    let formattedStartTime = startTime;
    for (let i = 0; i < numChunks; i++) {
      const startIndex = i * chunkSize;
      const endIndex = (i + 1) * chunkSize;

      const chunk = receiverVar.slice(startIndex, endIndex);

      console.log(chunk);

      const request = {};
      const content = {};
      if (serviceName === "otp") {
        request["sourceApplication"] = "test";
        request["category"] = "OTP";
        request["attemptCount"] = "1";

        const contentItem = {};
        contentItem["templateId"] = templateId;
        contentItem["locale"] = locale;
        contentItem["templateParameters"] = templateVar;

        if (channel === "email") {
          contentItem["senderName"] = emailSenderName;
          contentItem["sender"] = emailSender;
          contentItem["subject"] = emailSubject;
          contentItem["body"] = text;
          contentItem["receiver"] = emailReceiver;
          contentItem["subscriptionFlagEnabled"] = unsubscriptionFlag;
        } else {
          contentItem["text"] = text;
          contentItem["receiver"] =
            receiver.length > 0 && countryCode.length > 0
              ? receiver.substring(countryCode.length - 1)
              : null;
          contentItem["countryCode"] = countryCode;
        }

        content["sms"] = channel === "sms" ? contentItem : null;
        content["whatsapp"] = channel === "whatsapp" ? contentItem : null;
        content["email"] = channel === "email" ? contentItem : null;
        content["ivr"] = channel === "ivr" ? contentItem : null;
      } else {
        request["sourceApplication"] = "core-platform-portal";
        request["storeCallbackReport"] = storeCallbackReport;
        request["category"] = serviceName.toUpperCase();

        const defaultDate = new Date();
        defaultDate.setTime(defaultDate.getTime());
        const currentTime = dayjs(defaultDate).format("YYYY-MM-DD HH:mm:ss");
        const scheduledTime = formattedStartTime.format("YYYY-MM-DD HH:mm:ss");

        console.log("schedule after time : " + scheduledAfterTime);
        if (scheduledTime > currentTime) {
          request["deliveryTime"] = formattedStartTime.format(
            "YYYY-MM-DD HH:mm:ss"
          );
          console.log("Notification Scheduled at " + request["deliveryTime"]);
        } else {
          request["deliveryTime"] = null;
        }

        const contentItem = {};
        contentItem["templateId"] = templateId;
        contentItem["locale"] = locale;
        contentItem["users"] = [];

        if (channel === "email") {
          contentItem["senderName"] = emailSenderName;
          contentItem["sender"] = emailSender;
          contentItem["subject"] = emailSubject;
          contentItem["body"] = text;
          contentItem["subscriptionFlagEnabled"] = unsubscriptionFlag;

          formattedStartTime = formattedStartTime.add(
            scheduledAfterTime,
            "minute"
          );

          chunk.forEach((recieverItem) => {
            const currVarData = {};
            recieverItem.varList.map(
              (data) => (currVarData[data.key] = data.value)
            );

            let jsonEle = {};
            if (
              recieverItem.premiumUserId === undefined &&
              recieverItem.freeUserId === undefined
            ) {
              jsonEle = {
                emailAddress: recieverItem.reciever,
                templateParameters: currVarData,
              };
            } else if (recieverItem.premiumUserId !== undefined) {
              jsonEle = {
                premiumUserId: recieverItem.premiumUserId,
                templateParameters: currVarData,
              };
            } else if (recieverItem.freeUserId !== undefined) {
              jsonEle = {
                freeUserId: recieverItem.freeUserId,
                templateParameters: currVarData,
              };
            }

            contentItem["users"].push(jsonEle);
          });
        } else {
          contentItem["text"] = text;

          const tempCountryCode = chunk[0].countryCode;
          COUNTRIES.forEach((item) => {
            if (item.mobileCode === tempCountryCode) {
              setCountry(item.code);
            }
          });

          chunk.forEach((recieverItem) => {
            const currVarData = {};
            recieverItem.varList.map(
              (data) => (currVarData[data.key] = data.value)
            );
            let jsonEle = {};
            if (
              recieverItem.premiumUserId === undefined &&
              recieverItem.freeUserId === undefined
            ) {
              jsonEle = {
                phoneNumberCountryCode: recieverItem.countryCode,
                phoneNumber: recieverItem.reciever.slice(
                  recieverItem.countryCode.length - 1
                ), // Extracts the substring after the country code
                templateParameters: currVarData,
              };
            } else if (recieverItem.premiumUserId !== undefined) {
              jsonEle = {
                premiumUserId: recieverItem.premiumUserId,
                templateParameters: currVarData,
              };
            } else if (recieverItem.freeUserId !== undefined) {
              jsonEle = {
                freeUserId: recieverItem.freeUserId,
                templateParameters: currVarData,
              };
            }

            contentItem["users"].push(jsonEle);
          });
        }

        content["smsContent"] = channel === "sms" ? contentItem : null;
        content["whatsappContent"] =
          channel === "whatsapp" ? contentItem : null;
        content["emailContent"] = channel === "email" ? contentItem : null;
        content["ivrContent"] = channel === "ivr" ? contentItem : null;
      }

      request["trackingId"] = trackingId;
      request["metricParameters"] = {};
      channel === "email"
        ? (request["country"] = "IN")
        : (request["country"] = country === "" ? "IN" : country);
      request["content"] = content;

      handleSubmitNotificationRequest({
        request,
        serviceProvider,
        environment,
        serviceName,
      });
    }

    setUploadedCSV(false);
    setTrackingId("");
    setTemplateId("");
    setReceiverVar([]);
    setTemplateVar({});
    setReceiver("+91");
    setText("");
    setEmailReceiver("");
    setEmailSubject("");
    setEmailSenderName("");
    setEmailSender("");
    setLastTrackingIdToDisplay(trackingId);
  };

  const handleChange = (formVariable, event) => {
    setToastSuccessMessage(undefined);
    switch (formVariable) {
      case "emailReceiver":
        setEmailReceiver(event.target.value);
        break;
      case "emailSenderName":
        setEmailSenderName(event.target.value);
        break;
      case "templateId":
        setTemplateId(event.target.value);
        break;
      case "text":
        setText(event.target.value);
        break;
      case "startTime":
        setStartTime(event);
        break;
      case "emailSender":
        setEmailSender(event.target.value);
        break;
      case "emailSubject":
        setEmailSubject(event.target.value);
        break;
      case "trackingId":
        setTrackingId(event.target.value);
        break;
      default:
        break;
    }
  };

  const handlePhoneNumberOnChange = (value, country) => {
    setReceiver(value);
    setCountry(country.countryCode.toUpperCase());
    setCountryCode("+" + country.dialCode);
  };

  const handleServiceProviderOnChange = (event) => {
    setServiceProvider(event.target.value);
  };

  const handleSubmitNotificationRequest = async ({
    request,
    serviceProvider,
    environment,
    serviceName,
  }) => {
    try {
      setIsSubmitNotificationLoader(true);
      const response = await sendNotification({
        request,
        serviceProvider,
        environment,
        serviceName,
      });
      setIsSubmitNotificationLoader(false);

      const isScheduled = request["deliveryTime"] !== null;

      if (serviceName !== "otp") {
        if (response === undefined || response.status === undefined) {
          setToastErrorMessage("Request failed");
          setShowToast(true);
        } else if (
          response.status === 200 &&
          response.data.errorMessage === null
        ) {
          if (isScheduled) setToastSuccessMessage("Request scheduled");
          else setToastSuccessMessage("Request sent");
          setShowToast(true);
          setAlertDialogOpen(true);
        } else if (
          response.status === 200 &&
          response.data.errorMessage !== null
        ) {
          setToastErrorMessage(
            "Got 200 status with Error Message : " + response.data.errorMessage
          );
          setShowToast(true);
        } else {
          setToastErrorMessage(
            "request failed with status code : " + response.status
          );
        }
      } else {
        if (response === undefined || response.status === undefined) {
          setToastErrorMessage("request failed");
          setShowToast(true);
        } else if (response.status === 200) {
          setToastSuccessMessage("Request sent");
          setShowToast(true);
          setAlertDialogOpen(true);
        } else {
          setToastErrorMessage(
            "Request failed with status code : " + response.status
          );
          setShowToast(true);
        }
      }

      handleClose();
      return response;
    } catch (error) {
      setToastErrorMessage(error.message);
      setShowToast(true);
      handleClose();
    }
  };

  function getSuccess() {
    if (toastSuccessMessage !== undefined && showToast) {
      return (
        <ToastContainer
          data-testid="success-toast"
          position="bottom-end"
          className="p-3"
        >
          <Toast
            onClose={() => {
              setShowToast(false);
              setToastSuccessMessage(undefined);
            }}
            show={showToast}
            delay={responsePopupDisplayTime}
            autohide
            className="d-inline-block m-1"
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">
                Request processed successfully!
              </strong>
            </Toast.Header>
            <Toast.Body>{toastSuccessMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
      );
    }
  }

  function getError() {
    if (toastErrorMessage !== undefined && showToast) {
      return (
        <ToastContainer
          data-testid="error-toast"
          position="bottom-end"
          className="p-3"
        >
          <Toast
            onClose={() => {
              setShowToast(false);
              setToastErrorMessage(undefined);
            }}
            show={showToast}
            delay={responsePopupDisplayTime}
            autohide
            className="d-inline-block m-1"
            bg="danger"
          >
            <Toast.Header>
              <strong className="me-auto">Oh snap! You got an error!</strong>
            </Toast.Header>
            <Toast.Body>{toastErrorMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
      );
    }
  }

  return (
    <>
      <Container style={{ paddingTop: "50px", paddingLeft: "240px" }}>
        {addTemplatePopupVis && (
          <AddTemplatePopup
            templateVar={templateVar}
            editTemplateVarPopupHandler={addTemplatePopupHandler}
            updateTemplateVarHandler={updateTemplateVarHandler}
          />
        )}
        {addRecieverTemplatePopupVis && (
          <AddPhoneTemplatePopup
            receiverVar={receiverVar}
            addRecieverTemplatePopupHandler={addRecieverTemplatePopupHandler}
            channel={channel}
            updateRecieverVarList={updateRecieverVarList}
            uploadedCSV={uploadedCSV}
            setCountry={setCountry}
          />
        )}
        {bulkCSVVis && (
          <BulkCSVUpload
            BulkCSVHandler={BulkCSVHandler}
            BulkCSVUploadHandler={BulkCSVUploadHandler}
          />
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            value={channel}
            onChange={handleChannelChange}
            aria-label="icon label tabs example"
          >
            <Tab
              data-testid="sms-tab"
              icon={<MessageIcon />}
              label="SMS"
              value={"sms"}
              sx={{ color: "primary.light" }}
            />
            <Tab
              data-testid="email-tab"
              icon={<EmailIcon />}
              label="EMAIL"
              value={"email"}
              sx={{ color: "primary.light" }}
            />
            <Tab
              data-testid="whatsapp-tab"
              icon={<WhatsAppIcon />}
              label="WHATSAPP"
              value={"whatsapp"}
              sx={{ color: "primary.light" }}
            />
            <Tab
              data-testid="ivr-tab"
              icon={<CallIcon />}
              label="IVR"
              value={"ivr"}
              sx={{ color: "primary.light" }}
            />
          </Tabs>
        </div>
      </Container>
      <Divider sx={{ height: "2px", backgroundColor: "black" }}></Divider>
      <Container style={{ paddingTop: "25px", paddingLeft: "90px" }}>
        <Form onSubmit={handleShow}>
          <fieldset>
            <Row className="my-2">
              <Col md={1}></Col>
              <Col md={3}>
                <TextField
                  id="filled-templateId"
                  inputProps={{ "data-testid": "templateId" }}
                  label="Template Id"
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  style={{ width: "100%" }}
                  variant="outlined"
                  value={templateId}
                  onChange={(e) => handleChange("templateId", e)}
                  type="text"
                  required
                />
              </Col>
              <Col md={1}></Col>
              <Col md={1} style={{ paddingTop: "14px", marginLeft: "-15px" }}>
                <FormLabel
                  data-testid="locale"
                  id="demo-row-radio-buttons-group-label"
                  style={{ color: "black", fontSize: "17px" }}
                >
                  Locale
                </FormLabel>
              </Col>
              <Col md={4} style={{ marginLeft: "14px", paddingTop: "7px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleLocaleChange}
                  defaultValue={"en"}
                  style={{ marginLeft: "15px" }}
                >
                  <FormControlLabel
                    value="en"
                    style={{ color: "grey" }}
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="English"
                    data-testid="english-radio"
                  />
                  <FormControlLabel
                    value="ne"
                    style={{ color: "grey" }}
                    sx={{ marginLeft: "15px" }}
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="Non-English"
                    data-testid="nonenglish-radio"
                  />
                </RadioGroup>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={1}></Col>
              <Col md={3}>
                <Tooltip
                  title={
                    <span style={{ color: "white" }}>
                      <strong style={{ color: "white" }}>Optional : </strong>{" "}
                      <br />
                      if no serviceProvider is selected, <br /> default
                      serviceProvider will be assigned
                    </span>
                  }
                  placement="right"
                >
                  <span>
                    <FormControl
                      style={{
                        width: "100%",
                      }}
                    >
                      <InputLabel
                        data-testid="select-providers"
                        id="demo-multiple-checkbox-label"
                        style={{ color: "black" }}
                      >
                        Service Providers{" "}
                        <span style={{ fontSize: "10px" }}>(optional)</span>
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={serviceProvider}
                        onChange={handleServiceProviderOnChange}
                        input={<OutlinedInput label="Service Providers" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        disabled={serviceName !== "otp"}
                      >
                        {channel === "ivr" &&
                          ivrProviders.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={serviceProvider.includes(name)}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        {channel === "whatsapp" &&
                          whatsappProviders.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={serviceProvider.includes(name)}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        {channel === "email" &&
                          emailProviders.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={serviceProvider.includes(name)}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        {channel === "sms" &&
                          smsProviders.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={serviceProvider.includes(name)}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </span>
                </Tooltip>
              </Col>
              <Col md={1}></Col>
              <Col md={1} style={{ paddingTop: "18px" }}>
                <FormLabel
                  data-testid="environment"
                  id="demo-row-radio-buttons-group-label"
                  style={{ color: "black", fontSize: "17px" }}
                >
                  Environment
                </FormLabel>
              </Col>
              <Col md={4} style={{ paddingTop: "11px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleEnvironmentChange}
                  defaultValue={"prod"}
                  style={{ marginLeft: "15px" }}
                >
                  <FormControlLabel
                    value="prod"
                    style={{
                      color: "grey",
                      fontSize: "17px",
                    }}
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="Production"
                    data-testid="prod-radio"
                  />
                  <FormControlLabel
                    value="stage"
                    style={{
                      color: "grey",
                      fontSize: "17px",
                    }}
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="Staging"
                    data-testid="stage-radio"
                  />
                </RadioGroup>
              </Col>
            </Row>

            <Row className="my-2">
              <Col md={1}></Col>
              <Col md={3}>
                <Tooltip
                  title={"you can customize your tracking Id"}
                  placement="right"
                >
                  <TextField
                    inputProps={{ "data-testid": "trackingId" }}
                    sx={{ width: "100%" }}
                    id="text"
                    label={"Tracking ID"}
                    type="text"
                    value={trackingId}
                    onChange={(e) => handleChange("trackingId", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                  />
                </Tooltip>
              </Col>
              <Col md={1}></Col>
              <Col md={1} style={{ marginLeft: "-15px", paddingTop: "18px" }}>
                <FormLabel
                  data-testid="service"
                  id="demo-row-radio-buttons-group-label"
                  style={{ color: "#000000DE", fontSize: "17px" }}
                >
                  Service
                </FormLabel>
              </Col>
              <Col md={5} style={{ marginLeft: "14px", marginTop: "11px" }}>
                <RadioGroup
                  row
                  aria-labelledby="Service"
                  name="row-radio-buttons-group"
                  onChange={handleServiceChange}
                  defaultValue={"otp"}
                  style={{ marginLeft: "15px" }}
                >
                  <FormControlLabel
                    id={"Service"}
                    value="otp"
                    style={{ color: "grey" }}
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="OTP"
                  />
                  <FormControlLabel
                    id={"Service"}
                    data-testid="promotional-radio"
                    sx={{ marginLeft: "36px" }}
                    style={{ color: "grey" }}
                    value="promotional"
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="Promotional"
                  />
                  <FormControlLabel
                    id={"Service"}
                    data-testid="transactional-radio"
                    sx={{ marginLeft: "36px" }}
                    style={{ color: "grey" }}
                    value="transactional"
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="Transactional"
                  />
                </RadioGroup>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={1}></Col>
              <Col md={3}>
                <TextField
                  inputProps={{ "data-testid": "bodyortext-textfield" }}
                  sx={{ width: "100%" }}
                  id="text"
                  label={channel === "email" ? "Body" : "Text"}
                  type="text"
                  value={text}
                  onChange={(e) => handleChange("text", e)}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                />
              </Col>
              <Col md={1}></Col>
              <Col md={3} style={{ marginTop: "6px", marginLeft: "-31px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Tooltip
                    title={
                      serviceName === "otp" ? (
                        "Available only for promotional/transactional service"
                      ) : (
                        <span style={{ color: "white" }}>
                          <strong style={{ color: "white" }}>
                            Optional :{" "}
                          </strong>{" "}
                          <br />
                          set this parameter to schedule notification, <br />{" "}
                          else ignore.
                        </span>
                      )
                    }
                    placement="right"
                  >
                    <span>
                      <ThemeProvider theme={customTheme}>
                        <DesktopDateTimePicker
                          label="Scheduling Time"
                          value={dayjs(startTime)}
                          ampm={false}
                          onChange={(e) => handleChange("startTime", e)}
                          disabled={serviceName === "otp"}
                          data-testid="start-time"
                        />
                      </ThemeProvider>
                    </span>
                  </Tooltip>
                </LocalizationProvider>
              </Col>

              <Col md={3}>
                <div style={{ paddingTop: "13px" }}>
                  <span className="pt-1">Store Callback report</span>
                  <Switch
                    disabled={serviceName === "otp"}
                    checked={storeCallbackReport}
                    onChange={handleSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="my-2">
              <Col md={1}></Col>
              <Col md={3}>
                {serviceName === "otp" && (
                  <div>
                    {channel === "email" ? (
                      <Tooltip
                        title={"Enter Email Address"}
                        disableHoverListener={serviceName !== "otp"}
                      >
                        <span>
                          <TextField
                            inputProps={{ "data-testid": "emailSender" }}
                            sx={{ width: "100%" }}
                            required={serviceName === "otp"}
                            id="emailSender"
                            label="Receiver Email"
                            type="email"
                            value={emailReceiver}
                            onChange={(e) => handleChange("emailReceiver", e)}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            variant="outlined"
                          />
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={"Enter Phone Number"}
                        disableHoverListener={serviceName !== "otp"}
                        placement="right"
                      >
                        <span>
                          <Form.Group as={Col}>
                            <PhoneInput
                              id="receiverPhone"
                              country={"in"}
                              name={"receiverPhone"}
                              value={receiver}
                              onlyCountries={COUNTRIES.map((item) =>
                                item.code.toLocaleLowerCase()
                              )}
                              enableSearch={true}
                              onChange={handlePhoneNumberOnChange}
                              inputStyle={{ height: "55px", width: "100%" }}
                              inputProps={{ "data-testid": "phone-input" }}
                              disabled={serviceName !== "otp"}
                            />
                          </Form.Group>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                )}
              </Col>
              <Col md={7}></Col>
            </Row>

            {channel === "email" && (
              <Row className="my-2">
                <Col md={1}></Col>
                <Col md={3}>
                  <TextField
                    inputProps={{ "data-testid": "emailSubject" }}
                    sx={{ width: "100%" }}
                    required
                    id="emailSubject"
                    label="Email Subject"
                    type="text"
                    value={emailSubject}
                    onChange={(e) => handleChange("emailSubject", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                  />
                </Col>
              </Row>
            )}
            {channel === "email" && (
              <Row className="my-2">
                <Col md={1}></Col>
                <Col md={3}>
                  <TextField
                    inputProps={{ "data-testid": "senderEmail" }}
                    sx={{ width: "100%" }}
                    required
                    id="emailSender"
                    label="Sender Email"
                    type="text"
                    value={emailSender}
                    onChange={(e) => handleChange("emailSender", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                  />
                </Col>
              </Row>
            )}
            {channel === "email" && (
              <Row className="my-2">
                <Col md={1}></Col>
                <Col md={3}>
                  <TextField
                    inputProps={{ "data-testid": "senderName" }}
                    sx={{ width: "100%" }}
                    id="senderName"
                    label="Sender Name"
                    type="text"
                    value={emailSenderName}
                    onChange={(e) => handleChange("emailSenderName", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                  />
                </Col>
              </Row>
            )}

            {channel === "email" && (
              <Row className="my-2">
                <Col md={1}></Col>
                <Col md={3}>
                  <div style={{ paddingTop: "13px", textAlign: "left" }}>
                    <span className="pt-1">Unsubscription Link</span>
                    <Switch
                      checked={unsubscriptionFlag}
                      onChange={handleUnsubscriptionSwitchChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </Col>
              </Row>
            )}

            <Row className="my-3">
              <Col md={1}></Col>
              {serviceName === "otp" ? (
                <Col style={{ textAlign: "left" }} md={3}>
                  <CustomTooltip
                    title={
                      <pre>
                        <code>
                          {Object.keys(templateVar).length > 0
                            ? jsonElement
                            : "No template variables added"}
                        </code>
                      </pre>
                    }
                    arrow
                  >
                    <Button
                      variant="contained"
                      onClick={addTemplatePopupHandler}
                    >
                      {Object.keys(templateVar).length === 0
                        ? "Add template variable"
                        : `${
                            Object.keys(templateVar).length
                          } template variables added`}
                    </Button>
                  </CustomTooltip>
                </Col>
              ) : (
                <Col style={{ textAlign: "left" }} md={3}>
                  <Button
                    variant="contained"
                    onClick={addRecieverTemplatePopupHandler}
                  >
                    {uploadedCSV
                      ? `${receiverVar.length} users added - Preview`
                      : receiverVar.length === 0
                      ? "Add receiver with variables"
                      : `${receiverVar.length} receivers added`}
                  </Button>
                </Col>
              )}
              <Col md={1}></Col>
              <Col md={3}>
                <Button
                  variant="contained"
                  type="submit"
                  data-testid="test-submit-button"
                >
                  Submit
                </Button>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Row>
              <Col md={1}></Col>
              <Col md={2}>
                <p>OR</p>
              </Col>
              <Col md={7}></Col>
            </Row>
            <Row>
              <Col md={1}></Col>
              <Col md={2}>
                <Tooltip
                  title={"Available only for promotional/transactional service"}
                  disableHoverListener={serviceName !== "otp"}
                >
                  <span>
                    <Button
                      variant="contained"
                      onClick={BulkCSVHandler}
                      disabled={serviceName === "otp"}
                    >
                      Bulk CSV File Upload
                    </Button>
                  </span>
                </Tooltip>
              </Col>
              <Col md={7}></Col>
            </Row>
          </fieldset>
        </Form>
        <Modal show={show} onHide={handleClose} data-testid="test-modal">
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to send notification ? </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" value="false" onClick={handleClose}>
              Cancel
            </Button>

            {isSubmitNotificationLoader ? (
              <LoadingButton
                color="secondary"
                loading={true}
                loadingPosition="start"
                startIcon={<SendIcon />}
                variant="contained"
              >
                <span>Sending</span>
              </LoadingButton>
            ) : (
              <Button
                data-testid="test-modal-send-button"
                variant="primary"
                value="true"
                onClick={handleSubmit}
              >
                Send {channel === "email" ? "Email" : "SMS"}
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {getSuccess()}
        {getError()}
      </Container>

      <AlertDialog
        alertDialogOpen={alertDialogOpen}
        setAlertDialogOpen={setAlertDialogOpen}
        lastTrackingIdToDisplay={lastTrackingIdToDisplay}
      />
    </>
  );
}
export default SendNotificationForm;
