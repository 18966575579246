import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

function ConfirmationPopup({
  confirmation,
  closeConfirmationPopUp,
  handleAction,
  isLoading,
  action,
}) {
  return (
    <div>
      <Modal centered show={confirmation} onHide={closeConfirmationPopUp}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please confirm to {action[0]} the selected entry
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <LoadingButton
              color="secondary"
              loading={true}
              loadingPosition="start"
              variant="contained"
            >
              <span>{action[1]}</span>
            </LoadingButton>
          ) : (
            <Button
              data-testid="actionbutton-popup"
              variant="danger"
              onClick={(e) => handleAction(e)}
            >
              {action[0]}
            </Button>
          )}

          <Button
            data-testid="closebutton-popup"
            className="btn btn-secondary"
            onClick={closeConfirmationPopUp}
            autoFocus
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmationPopup;
