import React from "react";
import { useEffect, useRef } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getAllConfig,
  createConfig,
  deleteConfig,
  updateConfig,
} from "../Api/CrudApi";
import { useCrud } from "../Utils/Utils";
import { APPLICATION_OWNERS_COLUMN_PROPERTIES_LIST } from "../Utils/constants";

function ApplicationOwnerDetails() {
  const initialPageLoad = useRef(false);
  const heading = "Registered Applications & owner details";
  function getConfigObject(selectedObject) {
    return {
      id: selectedObject["id"],
      name: selectedObject["name"],
      hydraClientId: selectedObject["hydraClientId"],
      owners: selectedObject["owners"],
      createdBy: selectedObject["createdBy"],
      updatedBy: selectedObject["updatedBy"],
      createdAt: selectedObject["createdAt"],
      updatedAt: selectedObject["updatedAt"],
    };
  }
  function getModalBody(handleChange, selectedObject) {
    return (
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            data-testid="name"
            placeholder="Name of the application sent in payload"
            onChange={(e) => handleChange("name", e.target.value)}
            autoFocus
            value={selectedObject === undefined ? "" : selectedObject["name"]}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>Hydra Client ID</strong>
          </Form.Label>
          <Form.Control
            data-testid="hydra-client-id"
            placeholder="Hydra client id used for authentication"
            onChange={(e) => handleChange("hydraClientId", e.target.value)}
            autoFocus
            value={
              selectedObject === undefined
                ? ""
                : selectedObject["hydraClientId"]
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>Owners</strong>
          </Form.Label>
          <Form.Control
            data-testid="owners"
            type="email"
            multiple
            placeholder="owner1@byjus.com,owner2@byjus.com"
            onChange={(e) =>
              handleChange(
                "owners",
                e.target.value.split(",").map((item) => item.trim())
              )
            }
            value={selectedObject === undefined ? "" : selectedObject["owners"]}
            required
          />
        </Form.Group>
      </Modal.Body>
    );
  }
  const url =
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_APPLICATION_OWNERS_URL;
  const { getSortablePaginatedAndFilterableTable, orderBy, reloadData } =
    useCrud(
      heading,
      APPLICATION_OWNERS_COLUMN_PROPERTIES_LIST,
      getAllConfig,
      updateConfig,
      deleteConfig,
      createConfig,
      getModalBody,
      getConfigObject,
      "name",
      url
    );

  useEffect(() => {
    const fetchData = async () => {
      if (!initialPageLoad.current) {
        initialPageLoad.current = true;
        await reloadData(0, orderBy, "name", [], "10");
      }
    };

    fetchData().catch(console.error);
  });
  return <>{getSortablePaginatedAndFilterableTable(heading)}</>;
}

export default ApplicationOwnerDetails;
