import React from "react";
import { OPERATORS_SYMBOL_MAP } from "../Utils/constants";

const AllFiltersApplied = ({
  orderBy,
  property,
  allFiltersArrayOfStrings,
  setAllFiltersArrayOfStrings,
  allFiltersArrayOfObjects,
  setAllFiltersArrayOfObjects,
  reloadData,
  rowsPerPage,
}) => {
  async function handleRemoveFilter(filterToDeleteString, event) {
    const [column1, operation1, value1] = filterToDeleteString.split(" ");
    const filterToDeleteObject = {
      operator: OPERATORS_SYMBOL_MAP.get(operation1),
      property: column1,
      value: value1,
    };

    const allFiltersArrayOfStringsAfterUpdated =
      allFiltersArrayOfStrings.filter(
        (filter) => filter !== filterToDeleteString
      );
    setAllFiltersArrayOfStrings(allFiltersArrayOfStringsAfterUpdated);

    const filtersArrayAfterUpdated = allFiltersArrayOfObjects.filter(
      (filter) =>
        filter.operator !== filterToDeleteObject.operator ||
        filter.property !== filterToDeleteObject.property ||
        filter.value !== filterToDeleteObject.value
    );
    setAllFiltersArrayOfObjects(filtersArrayAfterUpdated);

    await reloadData(
      0,
      orderBy,
      property,
      filtersArrayAfterUpdated,
      rowsPerPage
    );
  }

  function createListItems(totalFiltersApplied) {
    const listItems = [];

    for (let i = 0; i < totalFiltersApplied; i++) {
      const filterType = allFiltersArrayOfStrings[i];
      listItems.push(
        <li style={{ listStyle: "none" }}>
          <div
            style={{
              border: "1px solid",
              color: "black",
              fontSize: "12px",
              borderRadius: "20px",
              padding: "5px 10px",
              marginLeft: "2px",
            }}
          >
            <strong>{filterType}</strong>
            <label>
              <span
                onClick={(event) => handleRemoveFilter(filterType, event)}
                style={{ paddingLeft: "10px", color: "black" }}
                data-testid="removefilter-select"
              >
                X
              </span>
            </label>
          </div>
        </li>
      );
    }

    return listItems;
  }

  return (
    <>
      <div style={{ display: "flex", marginBottom: "13px", marginTop: "0px" }}>
        <ul style={{ display: "contents" }}>
          {createListItems(allFiltersArrayOfStrings.length)}
        </ul>
      </div>
    </>
  );
};

export default AllFiltersApplied;
