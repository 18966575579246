import Cookies from "universal-cookie";
import { properties } from "../properties";
const cookies = new Cookies();

let payloadEmail;
try {
  const userDetailsCookie = cookies.get(properties.GOOGLE_USER_DETAILS_COOKIE);
  const decodedValue = atob(userDetailsCookie);
  const payload = JSON.parse(decodedValue).payload;
  payloadEmail = payload.email;
} catch (error) {
  console.error("InvalidCharacterError:", error.message);
}

export { payloadEmail };
