import React from "react";
import universalCookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { properties } from "../../properties.js";
import Button from "@mui/material/Button";
import SideBar from "../MUISideBar";
function Navbar() {
  const navigate = useNavigate();
  const cookies = new universalCookies();
  const handleLogout = () => {
    document.cookie =
      properties.GOOGLE_USER_DETAILS_COOKIE +
      "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=" +
      process.env.REACT_APP_DOMAIN_NAME +
      "; path=/;";
    document.cookie =
      properties.GOOGLE_REFRESH_TOKEN_METADATA_COOKIE +
      "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=" +
      process.env.REACT_APP_DOMAIN_NAME +
      "; path=/;";
    navigate("/login");
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          justifyContent: "center",
          right: "5px",
          top: "5px",
          height: "8vh",
          display: "flex",
        }}
      >
        {cookies.get(properties.GOOGLE_USER_DETAILS_COOKIE) ? (
          <Button
            sx={{ height: 40, margin: "5px" }}
            variant="contained"
            color={"primary"}
            onClick={handleLogout}
          >
            Logout
          </Button>
        ) : null}
      </div>
      {cookies.get(properties.GOOGLE_USER_DETAILS_COOKIE) ? (
        <div>
          <SideBar />
        </div>
      ) : null}
    </>
  );
}

export default Navbar;
