import { useState } from "react";

const InsertTemplateVar = ({ metadata, onChange, index }) => {
  const [key, setKey] = useState(
    metadata.key ? metadata.key : `var${index + 1}`
  );
  const [value, setValue] = useState(metadata.value);

  const handleKeyChange = (event) => {
    onChange({ key: event.target.value, value: value });
    setKey(event.target.value);
  };
  const handleValueChange = (event) => {
    onChange({ key: key, value: event.target.value });
    setValue(event.target.value);
  };

  return (
    <div>
      <div className="grid grid-cols-12 gap-2">
        <input
          type="text"
          value={key}
          maxLength="25"
          placeholder="Enter var"
          className="p-2 col-span-6  w-full rounded-md border  shadow-sm focus:border-primary focus:ring-primary text-body1"
          onChange={handleKeyChange}
        />
        <input
          type="text"
          value={value}
          maxLength="200"
          placeholder="Enter value"
          className="p-1 col-span-6  w-full  rounded-md border  shadow-sm  focus:border-primary focus:ring-primary text-body1"
          onChange={handleValueChange}
        />
      </div>
    </div>
  );
};

export default InsertTemplateVar;
