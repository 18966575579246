import axios from "axios";

const getScheduledNotifications = async ({ trackingId, environment }) => {
  const url =
    (environment === "stage"
      ? process.env.REACT_APP_STAGE_URL_PREFIX
      : process.env.REACT_APP_PROD_URL_PREFIX) +
    process.env.REACT_APP_SCHEDULED_NOTIFICATION_URL +
    "/trackingid";

  try {
    return await axios.get(url, {
      withCredentials: true,
      params: {
        trackingId: trackingId,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const disableScheduledNotification = async (requestId, environment) => {
  const url =
    (environment === "stage"
      ? process.env.REACT_APP_STAGE_URL_PREFIX
      : process.env.REACT_APP_PROD_URL_PREFIX) +
    process.env.REACT_APP_SCHEDULED_NOTIFICATION_URL +
    "/transactional/update-status";
  try {
    return await axios.post(
      url,
      {},
      {
        withCredentials: true,
        params: {
          requestId: requestId,
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export { getScheduledNotifications, disableScheduledNotification };
