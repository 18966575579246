import React from "react";
import Cookies from "universal-cookie";
import { properties } from "../../properties";
import { Navigate } from "react-router-dom";

const cookies = new Cookies();

export default function ProtectedRoute({ component }) {
  if (!cookies.get(properties.GOOGLE_USER_DETAILS_COOKIE)) {
    return <Navigate replace to="/login" />;
  } else {
    return component;
  }
}
