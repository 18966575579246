import React from "react";
import { useEffect, useRef } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import {
  getAllConfig,
  createConfig,
  deleteConfig,
  updateConfig,
} from "../Api/CrudApi";
import { useCrud } from "../Utils/Utils";
import { HEALTH_CHECK_COLUMN_PROPERTIES_LIST } from "../Utils/constants";
function HealthCheckConfig() {
  const supportedHttpMethods = ["GET", "POST"];

  const initialPageLoad = useRef(false);
  function getConfigObject(selectedObject) {
    return {
      id: selectedObject["id"],
      url: selectedObject["url"],
      httpMethod: selectedObject["httpMethod"],
      expectedStatus: selectedObject["expectedStatus"],
      owners: selectedObject["owners"],
      startTime: selectedObject["startTime"],
      endTime: selectedObject["endTime"],
      createdBy: selectedObject["createdBy"],
      updatedBy: selectedObject["updatedBy"],
      createdAt: selectedObject["createdAt"],
      updatedAt: selectedObject["updatedAt"],
    };
  }

  function getModalBody(handleChange, selectedObject) {
    return (
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>URL</Form.Label>
          <Form.Control
            required
            type="url"
            data-testid="url"
            placeholder="https://api.byjus.com/your-service/health"
            onChange={(e) => handleChange("url", e.target.value)}
            autoFocus
            value={selectedObject === undefined ? "" : selectedObject["url"]}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>Http Method</strong>
          </Form.Label>
          <Dropdown>
            <Dropdown.Toggle variant="light">
              {selectedObject === undefined
                ? handleChange("httpMethod", "GET")
                : selectedObject["httpMethod"]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {supportedHttpMethods.map((supportedHttpMethod) => (
                <Dropdown.Item
                  data-testid="httpMethod"
                  key={supportedHttpMethod}
                  onClick={() =>
                    handleChange("httpMethod", supportedHttpMethod)
                  }
                >
                  {supportedHttpMethod}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>Expected Status Code</strong>
          </Form.Label>
          <Form.Control
            required
            data-testid="expectedStatusCode"
            type="number"
            pattern="[0-9]+"
            placeholder="200"
            onChange={(e) =>
              handleChange("expectedStatus", parseInt(e.target.value))
            }
            value={
              selectedObject === undefined
                ? ""
                : selectedObject["expectedStatus"]
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>Owners</strong>
          </Form.Label>
          <Form.Control
            data-testid="owners"
            type="email"
            multiple
            placeholder="owner1@byjus.com,owner2@byjus.com"
            onChange={(e) =>
              handleChange(
                "owners",
                e.target.value.split(",").map((item) => item.trim())
              )
            }
            value={selectedObject === undefined ? "" : selectedObject["owners"]}
            required
          />
        </Form.Group>
        <Form.Label>
          (OPTIONAL: If there is a scheduled downtime in this service, specify
          the start & end times to monitor the endpoint)
        </Form.Label>
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>Start Time</strong>
          </Form.Label>
          <Form.Control
            data-testid="startTime"
            type="time"
            onChange={(e) =>
              handleChange(
                "startTime",
                e.target.value.length > 0 ? e.target.value + ":00" : undefined
              )
            }
            value={
              selectedObject === undefined ? "" : selectedObject["startTime"]
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>End Time</strong>
          </Form.Label>
          <Form.Control
            data-testid="endTime"
            type="time"
            onChange={(e) =>
              handleChange(
                "endTime",
                e.target.value.length > 0 ? e.target.value + ":00" : undefined
              )
            }
            value={
              selectedObject === undefined ? "" : selectedObject["endTime"]
            }
          />
        </Form.Group>
      </Modal.Body>
    );
  }
  const heading = "Periodic Health check configuration";
  const url =
    process.env.REACT_APP_BASE_URL + process.env.REACT_APP_HEALTH_CONFIG_URL;
  const { getSortablePaginatedAndFilterableTable, orderBy, reloadData } =
    useCrud(
      heading,
      HEALTH_CHECK_COLUMN_PROPERTIES_LIST,
      getAllConfig,
      updateConfig,
      deleteConfig,
      createConfig,
      getModalBody,
      getConfigObject,
      "url",
      url
    );

  useEffect(() => {
    const fetchData = async () => {
      if (!initialPageLoad.current) {
        initialPageLoad.current = true;
        await reloadData(0, orderBy, "url", [], "10");
      }
    };

    fetchData().catch(console.error);
  });
  return <>{getSortablePaginatedAndFilterableTable(heading)}</>;
}

export default HealthCheckConfig;
