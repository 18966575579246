import axios from "axios";

const getAllConfig = async (
  url,
  pageNumber,
  orderBy,
  property,
  filtersArray,
  errorHandler,
  rowsPerPage
) => {
  const pagingRequest = {
    page: pageNumber,
    size: rowsPerPage,
    searchCriteria: {
      filters: filtersArray,
    },
    sortField: [
      {
        direction: orderBy,
        property: property,
      },
    ],
  };
  let response = [];
  try {
    response = await axios.post(url + "/all", pagingRequest, {
      withCredentials: true,
    });
    if (response.data.error != null) {
      throw response.data.error;
    }
    return response;
  } catch (error) {
    errorHandler(error);
  }
  return response;
};

const createConfig = async (url, healthCheckConfig, errorHandler) => {
  return await axios
    .post(url, healthCheckConfig, {
      withCredentials: true,
      headers: {
        "content-type": "application/json",
      },
    })
    .then(
      (response) => {
        return response;
      },
      (error) => {
        errorHandler(error);
        return undefined;
      }
    );
};

const updateConfig = async (url, id, healthCheckConfig, errorHandler) => {
  return await axios
    .put(url, healthCheckConfig, {
      withCredentials: true,
      headers: {
        "content-type": "application/json",
      },
      params: {
        id: id,
      },
    })
    .then(
      (response) => {
        return response;
      },
      (error) => {
        errorHandler(error);
        return undefined;
      }
    );
};

const deleteConfig = async (url, id, errorHandler) => {
  return await axios
    .delete(url, {
      withCredentials: true,
      params: {
        id: id,
      },
    })
    .then(
      (response) => {
        return response;
      },
      (error) => {
        errorHandler(error);
        return undefined;
      }
    );
};

export { getAllConfig, createConfig, updateConfig, deleteConfig };
