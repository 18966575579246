import * as React from "react";
import Container from "react-bootstrap/Container";
import MyTable from "../Components/CheckBoxTable";
import Divider from "@mui/material/Divider";
function Subscription() {
  return (
    <>
      <Container style={{ paddingTop: "80px", paddingLeft: "240px" }}>
        <div>
          <p>
            Subscribed sources report will be sent regularly for daily weekely
            and monthly usage of SMS/Email/IVR/WhatsApp/RCS to the registered
            email address
          </p>
        </div>
      </Container>
      <Divider sx={{ height: "2px", backgroundColor: "black" }}></Divider>
      <Container style={{ paddingTop: "25px", marginLeft: "330px" }}>
        <MyTable />
      </Container>
    </>
  );
}

export default Subscription;
