import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyToClipboard } from "../../Utils/BaseUtils";

export default function AlertDialog({
  alertDialogOpen,
  setAlertDialogOpen,
  lastTrackingIdToDisplay,
}) {
  const handleClose = () => {
    setAlertDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        open={alertDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Copy the below trackingId to track the last notification."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "green" }}
          >
            {lastTrackingIdToDisplay}{" "}
            <Button
              variant="primary"
              startIcon={<ContentCopyIcon />}
              onClick={() => copyToClipboard(lastTrackingIdToDisplay)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
