import axios from "axios";
import { ADMIN_USERS } from "../Utils/constants";
import { payloadEmail } from "../Utils/auditLogUtils";

const auditLogEmails = async (
  configType,
  operationPerformed,
  config1,
  config2
) => {
  const body = JSON.stringify(config1, null, "&nbsp;").split("\n").join("<br>");

  const bodyAfterUpdate = JSON.stringify(config2, null, "&nbsp;")
    .split("\n")
    .join("<br>");

  const newBody =
    operationPerformed !== "updated "
      ? `<span style="font-size: 16px;"><b>${configType}${operationPerformed}by ${payloadEmail} in ${process.env.REACT_APP_ENVIRONMENT} :</b></span><br><br>${body}<br><br>`
      : `<span style="font-size: 16px;"><b>${configType}updated by ${payloadEmail} in ${process.env.REACT_APP_ENVIRONMENT} :</b></span> <br> <br> <br> <span style="font-size: 16px;"> <b>${configType}before updated - </b> </span>  <br> <br>${body}<br> <br> <br> <br> <span style="font-size: 16px;"> <b>${configType}after updated - </b> </span>  <br> <br>${bodyAfterUpdate}<br></br>`;

  const emailRequest = {
    category: "OTP",
    content: {
      email: {
        locale: "en",
        receiver: ADMIN_USERS[0],
        sender: "controlpanel.support@byjus.com",
        senderName: "Control Panel",
        subject: configType + operationPerformed + "via Control Panel",
        body: newBody,
        cc: ADMIN_USERS.slice(1),
      },
    },
    country: "IN",
    sourceApplication: "Communication-Platform",
  };

  const config = {
    withCredentials: true,
    headers: {
      "content-type": "application/json",
    },
    params: {
      serviceProviders: "sendgrid",
    },
  };
  const url =
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_SEND_NOTIFICATION_URL;

  return await axios.post(url, emailRequest, config);
};

export { auditLogEmails };
