import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import InsertTemplateVar from "./InsertTemplateVar";
import { nanoid } from "@reduxjs/toolkit";
import Button from "@mui/material/Button";

const AddTemplatePopup = ({
  templateVar,
  editTemplateVarPopupHandler,
  updateTemplateVarHandler,
}) => {
  const currMetadataList = [];
  Object.keys(templateVar).map((keyData) =>
    currMetadataList.push({
      key: keyData,
      value: templateVar[keyData],
      id: nanoid(),
    })
  );
  const [metadataList, setMetadataList] = useState(currMetadataList);
  const handleMetadataChange = (metadataOld, i) => {
    const list = [...metadataList];
    list[i] = {
      key: metadataOld.key.trim(),
      value: metadataOld.value.trim(),
      id: list[i].id,
    };
    setMetadataList([...list]);
  };

  const handleAddMetadataClick = () => {
    const metadadata_list = metadataList.map((metadataListItem) =>
      Object.assign({}, metadataListItem, metadataListItem)
    );
    metadadata_list.push({ key: "", value: "", id: nanoid() });
    setMetadataList(metadadata_list);
  };

  const handleCloseMetadataClick = (i) => {
    const metadataListCopy = metadataList.map((metadataListItem) =>
      Object.assign({}, metadataListItem, metadataListItem)
    );
    metadataListCopy.splice(i, 1);
    setMetadataList([...metadataListCopy]);
  };

  const handleSaveClick = () => {
    const metadadata_list = [];
    metadataList.forEach((metadataListItem) => {
      if (metadataListItem.key !== "" && metadataListItem.value !== "") {
        metadadata_list.push(metadataListItem);
      }
    });
    const currMetaData = {};
    metadadata_list.map((data) => (currMetaData[data.key] = data.value));
    updateTemplateVarHandler(currMetaData);
    editTemplateVarPopupHandler();
  };

  return (
    <div className="fixed bg-black inset-0 bg-opacity-10 flex justify-center items-center z-10 backdrop-blur-sm">
      <div className="bg-white pl-9 rounded-lg p-6 w-1/2 max-h-screen overflow-scroll">
        <div className="flex">
          <div className="flex-1 flex font-semibold text-xl text-text_meduimGrey	items-start w-fit h-fit ">
            Add Template Variable
          </div>
          <div className="flex">
            <CloseIcon
              data-testid="close-icon"
              onClick={editTemplateVarPopupHandler}
              fontSize="small"
              className="hover:cursor-pointer  hover:text-text_darkGrey p-1 bg-text_lighterGrey rounded-xl text-text_grey"
            />
          </div>
        </div>
        <div className="mt-px  md:mt-0 overflow-hidden">
          <div className="bg-white py-3 ">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-7">
                <label
                  htmlFor="role-name"
                  className="my-2 text-sm flex text-text_meduimGrey"
                >
                  {" "}
                  Variable{" "}
                </label>
                {metadataList.map((data, i) => (
                  <div key={data.id} className="mb-1 grid grid-cols-12">
                    <div className="col-span-11">
                      <InsertTemplateVar
                        metadata={data}
                        onChange={(e) => handleMetadataChange(e, i)}
                        index={i}
                      />
                    </div>
                    <div className=" col-span-1  mt-1 text-gray-400 ml-2 w-5 hover:cursor-pointer hover:text-text_darkGrey">
                      <Close
                        data-testid="close-metadata"
                        fontSize="small"
                        onClick={() => handleCloseMetadataClick(i)}
                      />
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="mt-2 text-primary text-body2 flex hover:cursor-pointer"
                  onClick={handleAddMetadataClick}
                >
                  + Add more Variable
                </button>
              </div>
            </div>
          </div>
          <div className=" py-3 text-right  flex space-x-2">
            <Button variant="contained" type="submit" onClick={handleSaveClick}>
              Update
            </Button>

            <Button variant="outlined" onClick={editTemplateVarPopupHandler}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTemplatePopup;
