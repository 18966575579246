import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";

function createData(
  count,
  requestSent,
  responseStatus,
  callbackStatus,
  timeTaken,
  serviceProvider,
  error,
  value
) {
  if (timeTaken !== "N/A") {
    timeTaken = timeTaken.substring(0, timeTaken.indexOf(" "));
  }
  return {
    count,
    requestSent,
    responseStatus,
    callbackStatus,
    timeTaken,
    serviceProvider,
    error,
    attempts: value.attempts,
    responses: value.responses,
    callbacks: value.callbacks,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const acceptedCallBackStatus = [
    "SUCCESS",
    "Success",
    "Delivered",
    "Sent",
    "sent",
    "queued",
    "delivered",
  ];

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="left">{row.count}</TableCell>
        <TableCell align="left">
          {convertGMTtoISTAndFormat(row.requestSent)}
        </TableCell>
        <TableCell
          style={
            row.responseStatus === "SUCCESS"
              ? { color: "green" }
              : { color: "red" }
          }
          align="left"
        >
          {row.responseStatus}
        </TableCell>
        <TableCell
          style={
            acceptedCallBackStatus.includes(row.callbackStatus)
              ? { color: "green" }
              : { color: "red" }
          }
          align="left"
        >
          {row.callbackStatus}
        </TableCell>
        <TableCell align="left">{row.timeTaken}</TableCell>
        <TableCell align="left">{row.serviceProvider}</TableCell>
        <TableCell
          style={
            row.error === "No Error" ? { color: "green" } : { color: "orange" }
          }
          align="left"
        >
          {row.error}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          data-testid="my-collapse"
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Attempts
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Timestamp</TableCell>
                    <TableCell align="left">Trace Id</TableCell>
                    <TableCell align="left">Attempt Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.attempts.map((attemptsRow) => (
                    <TableRow>
                      <TableCell align="left" component="th" scope="row">
                        {convertGMTtoISTAndFormat(attemptsRow.timestamp)}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {attemptsRow.traceId}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {attemptsRow.attemptMessage}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Response
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Timestamp</TableCell>
                    <TableCell align="left">Trace Id</TableCell>
                    <TableCell align="left">Service provider</TableCell>
                    <TableCell align="left">Time Taken(ns)</TableCell>
                    <TableCell align="left">Unique Id</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Error Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.responses.map((responsesRow) => (
                    <TableRow>
                      <TableCell align="left" component="th" scope="row">
                        {convertGMTtoISTAndFormat(responsesRow.timestamp)}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {responsesRow.traceId}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {responsesRow.serviceProviderName}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {responsesRow.timeTaken.substring(
                          0,
                          responsesRow.timeTaken.indexOf(" ")
                        )}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {responsesRow.uniqueId}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {responsesRow.status}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {responsesRow.errorMessage}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Callbacks
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Timestamp</TableCell>
                    <TableCell align="left">Unique Id</TableCell>
                    <TableCell align="left">Status Code</TableCell>
                    <TableCell align="left">Status Description</TableCell>
                    <TableCell align="left">Service Provider</TableCell>
                    <TableCell align="left">Time Taken(s)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.callbacks.map((callbacksRow) => (
                    <TableRow>
                      <TableCell align="left" component="th" scope="row">
                        {convertGMTtoISTAndFormat(callbacksRow.timestamp)}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {callbacksRow.uniqueId}{" "}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {callbacksRow.statusCode}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {callbacksRow.statusDescription}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {callbacksRow.serviceProviderName}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {callbacksRow.timeToResponse.substring(
                          0,
                          callbacksRow.timeToResponse.indexOf(" ")
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const isArrayEmpty = (data) => {
  return data === null || data === undefined || data.length === 0;
};

const rows = [];
const createTableData = (result) => {
  const acceptedResponseStatus = ["success", "200", "202"];
  while (rows.length > 0) rows.pop();
  var count = 0;
  for (var key in result) {
    var requestSent;
    var responseStatus;
    var callbackStatus;
    var timeTaken;
    var serviceProvider;
    var error;
    if (result.hasOwnProperty(key)) {
      const value = result[key];
      console.log("size: ", value.errors.length);
      requestSent = !isArrayEmpty(value.attempts)
        ? value.attempts[0].timestamp
        : "N/A";
      if (!isArrayEmpty(value.responses)) {
        responseStatus = "FAILED";
        for (var i = 0; i < value.responses.length; i++) {
          if (acceptedResponseStatus.includes(value.responses[i].status)) {
            responseStatus = "SUCCESS";
            break;
          }
        }
        if (requestSent === "N/A") {
          requestSent = value.responses[0].timestamp;
        }
      }
      callbackStatus = !isArrayEmpty(value.callbacks)
        ? value.callbacks.at(-1).statusDescription
        : "N/A";
      timeTaken = !isArrayEmpty(value.callbacks)
        ? value.callbacks.at(-1).timeToResponse
        : "N/A";
      serviceProvider = !isArrayEmpty(value.responses)
        ? value.responses.at(-1).serviceProviderName
        : "N/A";
      error = !isArrayEmpty(value.errors)
        ? value.errors.at(-1).message
        : "No Error";
      console.log(error);
      rows.push(
        createData(
          ++count,
          requestSent,
          responseStatus,
          callbackStatus,
          timeTaken,
          serviceProvider,
          error,
          value
        )
      );
    }
  }
};

function convertGMTtoISTAndFormat(gmtTimestamp) {
  const gmtDate = new Date(gmtTimestamp);

  const istOffset = 5.5 * 60 * 60 * 1000;
  const istTime = new Date(gmtDate.getTime() + istOffset);
  const istTimeFormatted = istTime.toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  return istTimeFormatted;
}

export default function CollapsibleTable({ result }) {
  createTableData(result);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      {JSON.stringify(result) !== "{}" && (
        <Paper>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Sl.No</TableCell>
                  <TableCell align="left">Request Sent Time</TableCell>
                  <TableCell align="left">Response Status</TableCell>
                  <TableCell align="left">Callback Status</TableCell>
                  <TableCell align="left">Time Taken(s)</TableCell>
                  <TableCell align="left">Service Provider</TableCell>
                  <TableCell align="left">Error</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <Row key={row.requestSent} row={row} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
}
