import axios from "axios";

const getTemplate = async (templateId, channel, errorHandler) => {
  let url =
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_TEMPLATE_URL +
    "/multiple";

  return await axios
    .get(url, {
      withCredentials: true,
      params: {
        templateId: templateId,
        channel: channel,
      },
    })
    .then(
      function (response) {
        return response;
      },
      (error) => {
        errorHandler(error);
        return [];
      }
    );
};

const createTemplate = async (
  templateId,
  channel,
  serviceProvider,
  account,
  templateMessage,
  numParams,
  owner,
  templateFile,
  subject,
  externalId,
  locale,
  activeChecked,
  source
) => {
  const url =
    process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_URL;
  const config = {
    withCredentials: true,
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const template = {
    account: account,
    active: activeChecked,
    channel: channel,
    createdBy: null,
    id: null,
    locale: locale,
    maxSize: 0,
    message: templateMessage,
    numParams: numParams,
    owner: owner,
    serviceProvider: serviceProvider,
    templateId: templateId,
    updatedBy: null,
    subject: subject,
    externalId: externalId,
    source: source === "" ? "core-platform" : source,
  };
  const templateJson = JSON.stringify(template);

  const blob = new Blob([templateJson], {
    type: "application/json",
  });

  const body = new FormData();
  body.append("template", blob);
  body.append("file", templateFile);

  console.log("Body : " + body.data);

  const response = await axios.post(url, body, config);
  return response;
};

const deleteTemplate = async (id, errorHandler) => {
  const url =
    process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_URL;
  return await axios
    .delete(url, {
      withCredentials: true,
      params: {
        id: id,
      },
    })
    .then(
      (response) => {
        return response;
      },
      (error) => {
        errorHandler(error);
        return undefined;
      }
    );
};

const updateTemplate = async (
  id,
  templateId,
  channel,
  serviceProvider,
  account,
  templateMessage,
  numParams,
  owner,
  templateFile,
  subject,
  externalId,
  locale,
  activeChecked,
  source
) => {
  const url =
    process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_URL;
  const config = {
    withCredentials: true,
    headers: {
      "content-type": "multipart/form-data",
    },
    params: {
      id,
    },
  };

  const template = {
    id: id,
    account: account,
    active: activeChecked,
    channel: channel,
    locale: locale,
    maxSize: 0,
    message: templateMessage,
    numParams: numParams,
    serviceProvider: serviceProvider,
    templateId: templateId,
    createdBy: null,
    updatedBy: null,
    owner: owner,
    subject: subject,
    externalId: externalId,
    source: source === "" ? "core-platform" : source,
  };
  const templateJson = JSON.stringify(template);

  const blob = new Blob([templateJson], {
    type: "application/json",
  });

  const body = new FormData();
  body.append("template", blob);
  body.append("file", templateFile);

  const response = await axios.put(url, body, config);
  return response;
};

export { getTemplate, createTemplate, deleteTemplate, updateTemplate };
