import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Pages/home";
import "bootstrap/dist/css/bootstrap.min.css";
import GetTemplateForm from "./Pages/GetTemplateForm";
import SearchNotificationStatus from "./Pages/SearchNotificationStatus";
import ScheduledNotifications from "./Pages/ScheduledNotifications";
import SendNotificationForm from "./Pages/SendNotificationForm";
import NotFound from "./Pages/NotFound";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import Navbar from "./Components/Navbar";
import RateLimitConfig from "./Pages/RateLimitConfig";
import FreeMarkerTemplatePlayground from "./Pages/FreeMarkerTemplatePlayground";
import HealthCheckConfig from "./Pages/HealthCheckConfig";
import LoginPage from "./Pages/LoginPage";
import SideBar from "../src/Components/MUISideBar";
import ApplicationOwnerDetails from "./Pages/ApplicationOwnerDetails";
import { ThemeProvider } from "@mui/material";
import theme from "./assets/data/theme";
import AdminProtectedRoute from "./Components/ProtectedRoute/AdminProtectedRoute";
import Subscription from "./Pages/Subscription";
import ApplicationConfiguration from "./Pages/ApplicationConfiguration";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App" style={{ width: "100%", height: "100%" }}>
            <Navbar />
            <Routes>
              <Route
                exact
                path="/"
                element={<ProtectedRoute component={<Home />} />}
              />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/sideBar" element={<SideBar />} />
              <Route
                path="/ratelimit"
                element={
                  <AdminProtectedRoute component={<RateLimitConfig />} />
                }
              />
              <Route
                path="/template/preview"
                element={
                  <ProtectedRoute
                    component={<FreeMarkerTemplatePlayground />}
                  />
                }
              />
              <Route
                path="/subscriptions"
                element={<ProtectedRoute component={<Subscription />} />}
              />
              <Route
                path="/template/get"
                element={<ProtectedRoute component={<GetTemplateForm />} />}
              />
              <Route
                path="/searchNotificationStatus"
                key={"searchNotificationStatus"}
                element={
                  <ProtectedRoute component={<SearchNotificationStatus />} />
                }
              />
              <Route
                path="/sendNotification"
                key={"sendNotification"}
                element={
                  <ProtectedRoute component={<SendNotificationForm />} />
                }
              />
              <Route
                path="/healthCheckConfig"
                key={"healthCheckConfig"}
                element={
                  <AdminProtectedRoute component={<HealthCheckConfig />} />
                }
              />
              <Route
                path="/applicationOwnerDetails"
                key={"applicationOwnerDetails"}
                element={
                  <AdminProtectedRoute
                    component={<ApplicationOwnerDetails />}
                  />
                }
              />
              <Route
                path="/scheduledNotifications"
                key={"scheduledNotifications"}
                element={
                  <ProtectedRoute component={<ScheduledNotifications />} />
                }
              />
              <Route
                path="/applicationConfiguration"
                key={"applicationConfiguration"}
                element={
                  <AdminProtectedRoute
                    component={<ApplicationConfiguration />}
                  />
                }
              />
              <Route exact path="/notfound" element={<NotFound />} />
              <Route path="*" element={<Navigate to="/notfound" replace />} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
