import axios from "axios";

const searchMessageStatus = async ({
  traceId,
  receiver,
  trackingId,
  serviceName,
  environment,
  channel,
  startTime,
  endTime,
}) => {
  const url =
    process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ELASTICSEARCH_URL;
  return await axios.post(url, {
    receiver,
    traceId,
    trackingId,
    serviceName,
    environment,
    channel,
    startTime,
    endTime,
  });
};

export { searchMessageStatus };
