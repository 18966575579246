import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

const MyTable = () => {
  const [rows, setRows] = useState([
    {
      id: 1,
      name: "K3",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 2,
      name: "mt-nucleus",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 3,
      name: "event-service",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 4,
      name: "at-nucleus",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 5,
      name: "TLLMS",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 6,
      name: "AB-Tutor-Plus",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 7,
      name: "Identity",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 8,
      name: "DF-LOS",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 9,
      name: "payment-app",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 10,
      name: "kart",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 11,
      name: "oms-app",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 12,
      name: "DF-CMS",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 13,
      name: "DF-ACHIEVE",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 14,
      name: "SalesCRMBackend",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 15,
      name: "workforce-management-system",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 16,
      name: "assessbackend",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 17,
      name: "SalesPerformanceManagement",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 18,
      name: "SalesUserManagement",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 19,
      name: "Vidyartha",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 20,
      name: "grade-up",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 21,
      name: "wt-nucleus",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 22,
      name: "DSSLBackend",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
    {
      id: 23,
      name: "aakashbyjus",
      columns: [
        { id: 1, isChecked: false },
        { id: 2, isChecked: false },
        { id: 3, isChecked: false },
        { id: 4, isChecked: false },
      ],
    },
  ]);

  const handleCheckboxChange = (event, row, column) => {
    const updatedRows = rows.map((item) => {
      if (item.id === row.id) {
        const updatedColumns = item.columns.map((col) => {
          if (col.id === column.id) {
            return { ...col, isChecked: event.target.checked };
          }
          return col;
        });
        return { ...item, columns: updatedColumns };
      }
      return item;
    });

    setRows(updatedRows);
  };

  return (
    <TableContainer>
      <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Service Source
            </TableCell>
            <TableCell
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Event Count{" "}
            </TableCell>
            <TableCell
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Template Data
            </TableCell>
            <TableCell
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Vendor Data
            </TableCell>
            <TableCell
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              International Data
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                {row.name}
              </TableCell>
              {row.columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  <Checkbox
                    data-testid="checkbox"
                    checked={column.isChecked}
                    onChange={(event) =>
                      handleCheckboxChange(event, row, column)
                    }
                    style={{ color: "#823598" }}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyTable;
