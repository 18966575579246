import { Menu, MenuItem, Select, TextField } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { OPERATORS_SYMBOL_MAP } from "../Utils/constants";

const FilterDropDownUtil = ({
  orderBy,
  property,
  column,
  setColumn,
  selectedValue,
  setSelectedValue,
  operation,
  setOperation,
  textOperations,
  allFiltersArrayOfStrings,
  setAllFiltersArrayOfStrings,
  allFiltersArrayOfObjects,
  setAllFiltersArrayOfObjects,
  reloadData,
  filteringColumns,
  rowsPerPage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = (event) => {
    setAnchorEl(null);
    setOperation("");
    setColumn("");
    setSelectedValue("");
  };

  const handleColumnChange = (event) => {
    const selectedColumn = event.target.value;
    setColumn(selectedColumn);
    handleValueChange({ target: { value: "" } });
  };

  const handleOperationChange = (event) => {
    const selectedOperation = event.target.value;
    setOperation(selectedOperation);
    handleValueChange({ target: { value: "" } });
  };

  const handleValueChange = async (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const handleApply = async () => {
    const newFilter = {
      operator: operation,
      property: column,
      value: selectedValue,
    };

    const filterAsString =
      column + " " + OPERATORS_SYMBOL_MAP.get(operation) + " " + selectedValue;
    const updatedFiltersAsStringArray = [
      ...allFiltersArrayOfStrings,
      filterAsString,
    ];
    setAllFiltersArrayOfStrings(updatedFiltersAsStringArray);

    const updatedFiltersArray = [...allFiltersArrayOfObjects, newFilter];
    setAllFiltersArrayOfObjects(updatedFiltersArray);

    await reloadData(0, orderBy, property, updatedFiltersArray, rowsPerPage);
    handleFilterMenuClose();
  };

  function filterWithProperNaming(filteringColumn) {
    return filteringColumn.replace(
      /[A-Z]/g,
      (match) => ` ${match.toLowerCase()}`
    );
  }

  function displayFilteringColumns(totalFilteringColumns) {
    const listItems = [];
    for (let i = 0; i < totalFilteringColumns; i++) {
      const filteringColumn = filteringColumns[i];
      listItems.push(
        <MenuItem value={filteringColumn}>
          {filterWithProperNaming(filteringColumn)}
        </MenuItem>
      );
    }
    return listItems;
  }

  return (
    <>
      <Button
        size="large"
        onClick={handleFilterMenuOpen}
        style={{ display: "flex" }}
        startIcon={<FilterAltIcon />}
        variant="outlined"
      >
        Filter
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleFilterMenuClose}
        PaperProps={{
          style: {
            width: "300px",
          },
        }}
      >
        <MenuItem>
          <ListItemText>•Column</ListItemText>
          <Select
            style={{ minWidth: "150px" }}
            onChange={handleColumnChange}
            data-testid="column-select"
            defaultValue={""}
          >
            {displayFilteringColumns(filteringColumns.length)}
          </Select>
        </MenuItem>

        <MenuItem>
          <ListItemText>•Operation</ListItemText>
          <Select
            style={{ minWidth: "150px" }}
            onChange={handleOperationChange}
            data-testid="operation-select"
            defaultValue={""}
          >
            {!textOperations.includes(column) && (
              <MenuItem value="EQUALS">Equals</MenuItem>
            )}
            {!textOperations.includes(column) && (
              <MenuItem value="NOT_EQUALS">Not equals</MenuItem>
            )}
            {!textOperations.includes(column) && (
              <MenuItem value="GREATER_THAN">Greater than</MenuItem>
            )}
            {!textOperations.includes(column) && (
              <MenuItem value="LESS_THAN">Less than</MenuItem>
            )}
            {!textOperations.includes(column) && (
              <MenuItem value="GREATER_THAN_OR_EQUAL_TO">
                Greater than or equal to
              </MenuItem>
            )}
            {!textOperations.includes(column) && (
              <MenuItem value="LESS_THAN_OR_EQUAL_TO">
                Less than or equal to
              </MenuItem>
            )}
            {textOperations.includes(column) && (
              <MenuItem data-testid="contains-select" value="CONTAINS">
                CONTAINS
              </MenuItem>
            )}
            {textOperations.includes(column) && (
              <MenuItem value="DOES_NOT_CONTAIN">DOES_NOT_CONTAIN</MenuItem>
            )}
          </Select>
        </MenuItem>

        <MenuItem>
          <ListItemText>•Value</ListItemText>
          <TextField
            style={{ width: "150px" }}
            value={selectedValue}
            onChange={handleValueChange}
            data-testid="value-select"
          />
        </MenuItem>

        <MenuItem style={{ display: "flex", float: "right" }}>
          <Button
            onClick={handleApply}
            disabled={!(column && selectedValue && operation)}
            data-testid="applyfilter-select"
          >
            •Apply filter
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};

export default FilterDropDownUtil;
