import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import axios from "axios";
import {
  createTemplate,
  deleteTemplate,
  getTemplate,
  updateTemplate,
} from "../Api/templateApi";
import { Row, Col, ToastContainer, Toast } from "react-bootstrap";
import { CircularProgress, Tab, Tabs, TextField } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from "@mui/icons-material/Email";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import CreateIcon from "@mui/icons-material/Create";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";
import Divider from "@mui/material/Divider";
import FreeMarkerTemplatePlayground from "./FreeMarkerTemplatePlayground";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Switch from "@mui/material/Switch";
import ConfirmationPopup from "../Components/Popups/ConfirmationPopup";

function GetTemplateForm() {
  const [locale, setLocale] = useState("en");
  const [templateId, setTemplateId] = useState("");
  const [channel, setChannel] = useState("SMS");
  const [serviceProvider, setServiceProvider] = useState("");
  const [account, setAccount] = useState("");
  const [templateMessage, setTemplateMessage] = useState("");
  const [numParams, setNumParams] = useState(0);
  const [owner, setOwner] = useState("");
  const [source, setSource] = useState("");
  const [subject, setSubject] = useState("");
  const [templateFile, setTemplateFile] = useState(null);
  const [externalId, setExternalId] = useState("");
  const [value, setValue] = useState("SMS");

  const [isPreview, setIsPreview] = useState(false);
  const [htmlBodyText, setHtmlBodyText] = useState();

  const [emailTemplateMessage, setEmailTemplateMessage] = useState({});

  const [templateIdToDelete, setTemplateIdToDelete] = useState("");
  const [templateIdToUpdate, setTemplateIdToUpdate] = useState();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [showCreateConfirmation, setShowCreateConfirmation] = useState(false);

  const [showResponse, setShowResponse] = useState(true);
  const [restrictEditing, setRestrictEditing] = useState(true);
  const [isCreate, setIsCreate] = useState(true);

  const [templateError, setTemplateError] = useState();
  const [activeChecked, setActiveChecked] = React.useState(true);
  const [templateSuccess, setTemplateSuccess] = useState("");
  const [showToast, setShowToast] = useState(true);
  const [isCreateTemplateLoading, setIsCreateTemplateLoading] = useState(false);
  const [isUpdateTemplateLoading, setIsUpdateTemplateLoading] = useState(false);
  const [isDeleteTemplateLoading, setIsDeleteTemplateLoading] = useState(false);
  const [isSearchTemplateIdLoading, setIsSearchTemplateIdLoading] =
    useState(true);

  const handleLocaleChange = (event, newValue) => {
    setLocale(newValue);
  };

  const handleSwitchChange = (event) => {
    setActiveChecked(event.target.checked);
  };

  const get = async (templateId) => {
    setIsPreview(false);
    setIsSearchTemplateIdLoading(false);
    const response = await getTemplate(templateId, channel, (error) => {
      setTemplateError(error);
      setShowToast(true);
    });
    setIsSearchTemplateIdLoading(true);
    if (response !== undefined && response.data.length > 0) {
      setShowResponse(false);
      setRestrictEditing(true);

      setTemplateIdToDelete(response.data[0].id);
      setTemplateIdToUpdate(response.data[0].id);

      setTemplateSuccess("Template is displayed");
      setShowToast(true);

      if (channel === "EMAIL") {
        setEmailTemplateMessage(response.data[0].message);
        setRestrictEditing(false);
      }

      setIsCreate(false);
      setTemplateMessage(response.data[0].message);
      setAccount(response.data[0].account);
      setLocale(response.data[0].locale);
      setExternalId(response.data[0].externalId);
      setNumParams(response.data[0].numParams);
      setServiceProvider(response.data[0].serviceProvider);
      setOwner(response.data[0].owner);
      setSubject(response.data[0].subject);
      setTemplateFile(response.data[0].templateFile);
      setActiveChecked(response.data[0].active);
      setSource(response.data[0].source);
    } else {
      setShowResponse(false);
      setRestrictEditing(false);

      setTemplateError("No Template with that template ID and channel");
      setShowToast(true);

      setEmptyValues();
    }
  };

  const setEmptyValues = () => {
    setIsCreate(true);
    setTemplateMessage("");
    setAccount("");
    setLocale("en");
    setExternalId("");
    setNumParams(0);
    setServiceProvider("");
    setOwner("");
    setSubject("");
    setTemplateFile(null);
    setSource("core-platform");
  };

  const handleSubmit = (templateId) => {
    get(templateId).catch(console.error);
  };

  const onFileChange = (event) => {
    setTemplateFile(event.target.files[0]);
  };

  const handleChange = (formVariable, event) => {
    switch (formVariable) {
      case "templateId":
        setTemplateId(event.target.value);
        if (event.target.value !== "") handleSubmit(event.target.value);
        else {
          setEmptyValues();
          setShowResponse(true);
          setRestrictEditing(true);
        }
        break;
      case "serviceProvider":
        setServiceProvider(event.target.value);
        break;
      case "account":
        setAccount(event.target.value);
        break;
      case "templateMessage":
        setTemplateMessage(event.target.value);
        break;
      case "numParams":
        setNumParams(event.target.value.replace(/[^0-9]/, ""));
        break;
      case "locale":
        setLocale(event.target.value);
        break;
      case "owner":
        setOwner(event.target.value);
        break;
      case "subject":
        setSubject(event.target.value);
        break;
      case "externalId":
        setExternalId(event.target.value);
        break;
      case "source":
        setSource(event.target.value);
        break;
      default:
        break;
    }
  };

  function handleDelete(event) {
    event.preventDefault();
    const deleteData = async () => {
      setIsDeleteTemplateLoading(true);
      const response = await deleteTemplate(templateIdToDelete, (error) => {
        setTemplateError(error);
        setShowToast(true);
      });
      setIsDeleteTemplateLoading(false);

      if (response !== undefined) {
        setEmptyValues();
        setTemplateSuccess("Successfully deleted the template.");
        setShowToast(true);
      }
      closeDeleteConfirmationPopUp();
    };

    deleteData().catch(console.error);
  }

  function closeDeleteConfirmationPopUp() {
    setShowDeleteConfirmation(false);
  }

  function showDeleteConfirmationPopUp(event, id) {
    if (event !== undefined && id !== undefined && id !== null) {
      event.preventDefault();
      setShowDeleteConfirmation(true);
    }
    return (
      <ConfirmationPopup
        confirmation={showDeleteConfirmation}
        closeConfirmationPopUp={closeDeleteConfirmationPopUp}
        handleAction={handleDelete}
        isLoading={isDeleteTemplateLoading}
        action={["delete", "deleting"]}
      />
    );
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsUpdateTemplateLoading(true);
    const response = await updateTemplate(
      templateIdToUpdate,
      templateId,
      channel,
      serviceProvider,
      account,
      templateMessage,
      numParams,
      owner,
      templateFile,
      subject,
      externalId,
      locale,
      activeChecked,
      source
    );
    setIsUpdateTemplateLoading(false);

    if (response !== undefined) {
      setTemplateSuccess("Template Updated Successfully");
      setShowToast(true);
    }
    closeUpdateConfirmationPopUp();
  };

  function closeUpdateConfirmationPopUp() {
    setShowUpdateConfirmation(false);
  }

  function showUpdateConfirmationPopUp(event, id) {
    if (event !== undefined && id !== undefined && id !== null) {
      event.preventDefault();
      setTemplateIdToUpdate(id);
      setShowUpdateConfirmation(true);
    }
    return (
      <ConfirmationPopup
        confirmation={showUpdateConfirmation}
        closeConfirmationPopUp={closeUpdateConfirmationPopUp}
        handleAction={handleUpdate}
        isLoading={isUpdateTemplateLoading}
        action={["update", "updating"]}
      />
    );
  }

  function showCreateConfirmationPopup(event) {
    event.preventDefault();
    setShowCreateConfirmation(true);
  }

  const handleCreate = async (e) => {
    e.preventDefault();
    setIsCreateTemplateLoading(true);
    const response = await createTemplate(
      templateId,
      channel,
      serviceProvider,
      account,
      templateMessage,
      numParams,
      owner,
      templateFile,
      subject,
      externalId,
      locale,
      activeChecked,
      source
    );
    setIsCreateTemplateLoading(false);
    setTemplateIdToDelete(response.data.data.id);
    setTemplateIdToUpdate(response.data.data.id);

    if (response.data.data !== null) setIsCreate(false);

    if (response !== undefined) {
      setTemplateSuccess("Successfully created the template.");
      setShowToast(true);
    }
    closeCreateConfirmationPopUp();
  };
  function closeCreateConfirmationPopUp() {
    setShowCreateConfirmation(false);
  }

  function showCreateConfirmationPopUp() {
    return (
      <ConfirmationPopup
        confirmation={showCreateConfirmation}
        closeConfirmationPopUp={closeCreateConfirmationPopUp}
        handleAction={handleCreate}
        isLoading={isCreateTemplateLoading}
        action={["create", "creating"]}
      />
    );
  }

  function handlePreview() {
    if (isPreview === true) setIsPreview(false);
    else setIsPreview(true);
  }

  function getSuccess() {
    if (templateSuccess !== undefined && showToast) {
      return (
        <ToastContainer
          data-testid="success-toast"
          position="bottom-end"
          className="p-3"
        >
          <Toast
            onClose={() => {
              setShowToast(false);
              setTemplateSuccess(undefined);
            }}
            show={showToast}
            delay={3000}
            autohide
            className="d-inline-block m-1"
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">
                Request processed successfully!
              </strong>
            </Toast.Header>
            <Toast.Body>{templateSuccess}</Toast.Body>
          </Toast>
        </ToastContainer>
      );
    }
  }

  function getError() {
    if (templateError !== undefined) {
      return (
        <ToastContainer
          data-testid="error-toast"
          position="bottom-end"
          className="p-3"
        >
          <Toast
            onClose={() => {
              setShowToast(false);
              setTemplateError(undefined);
            }}
            show={showToast}
            delay={3000}
            autohide
            className="d-inline-block m-1"
            bg="danger"
          >
            <Toast.Header>
              <strong className="me-auto">Oh snap! You got an error!</strong>
            </Toast.Header>
            <Toast.Body>{String(templateError)}</Toast.Body>
          </Toast>
        </ToastContainer>
      );
    }
  }

  const handleDownload = async () => {
    const url =
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_TEMPLATE_URL +
      "/download";
    setIsPreview(true);
    try {
      const response = await axios.get(url, {
        withCredentials: true,
        params: {
          path: emailTemplateMessage,
        },
        responseType: "blob",
      });
      setHtmlBodyText(response);
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const Url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = Url;
      link.setAttribute("download", "emailTemplateFile.html");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(Url);
    } catch {
      setTemplateError("Could not download the file");
      setShowToast(true);
    }
  };

  const handleSelect = (event, newValue) => {
    setChannel(newValue);
    setValue(newValue);
    setTemplateId("");
    setEmptyValues();
  };

  return (
    <>
      <Container style={{ paddingTop: "50px", paddingLeft: "240px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            data-testid="channelButton"
            style={{ display: "inline-flex" }}
            value={value}
            onChange={handleSelect}
            aria-label="icon label tabs example"
          >
            <Tab
              data-testid="smsButton"
              value={"SMS"}
              icon={<MessageIcon />}
              label="SMS"
              sx={{ color: "primary.light" }}
            />
            <Tab
              data-testid="emailButton"
              value={"EMAIL"}
              icon={<EmailIcon />}
              label="EMAIL"
              sx={{ color: "primary.light" }}
            />
          </Tabs>
        </div>
      </Container>

      <Divider sx={{ height: "2px", backgroundColor: "black" }}></Divider>
      <Container style={{ paddingTop: "11px" }}>
        <fieldset>
          <Form.Group className="mb-3">
            <Row>
              <Col style={{ paddingLeft: "76px" }} md={2}>
                {" "}
              </Col>
              <Col style={{ textAlign: "left", paddingLeft: "42px" }} md={4}>
                <TextField
                  sx={{ width: "100%" }}
                  id="filled-templateId"
                  inputProps={{ "data-testid": "templateId" }}
                  label="Template Id"
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  variant="outlined"
                  value={templateId}
                  onChange={(e) => handleChange("templateId", e)}
                  type="text"
                  required
                  helperText="Enter Template Id to create/update a template!"
                  FormHelperTextProps={{
                    style: { color: "black" },
                  }}
                />
              </Col>
              <Col md={1}></Col>
              <Col md={1} style={{ marginLeft: "-15px", paddingTop: "20px" }}>
                <FormLabel
                  data-testid="locale"
                  id="demo-row-radio-buttons-group-label"
                  style={{ color: "#000000DE", fontSize: "17px" }}
                >
                  Locale
                </FormLabel>
              </Col>
              <Col md={4} style={{ marginLeft: "14px", paddingTop: "12px" }}>
                <RadioGroup
                  value={locale}
                  row
                  aria-labelledby="Locale"
                  name="row-radio-buttons-group"
                  onChange={handleLocaleChange}
                  defaultValue={"en"}
                >
                  <FormControlLabel
                    disabled={restrictEditing}
                    id={"Locale"}
                    value="en"
                    style={{ color: "grey" }}
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="english"
                  />
                  <FormControlLabel
                    disabled={restrictEditing}
                    id={"Locale"}
                    sx={{ marginLeft: "35px" }}
                    style={{ color: "grey" }}
                    value="ne"
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="non english"
                  />
                </RadioGroup>
              </Col>
              <Col md={3}></Col>
            </Row>
            <Row className="justify-content-center">
              {!isSearchTemplateIdLoading && (
                <Col md={10}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </div>
                </Col>
              )}
            </Row>
          </Form.Group>
        </fieldset>
        {channel === "SMS" && (
          <Form
            onSubmit={
              isCreate
                ? (e) => showCreateConfirmationPopup(e)
                : (e) => showUpdateConfirmationPopUp(e, templateIdToUpdate)
            }
          >
            <fieldset>
              <Row>
                <Col style={{ paddingLeft: "76px" }} md={2}>
                  {" "}
                </Col>
                <Col style={{ textAlign: "left", paddingLeft: "42px" }} md={4}>
                  <TextField
                    disabled={restrictEditing}
                    id="filled-number"
                    sx={{ width: "100%" }}
                    inputProps={{ "data-testid": "numParams" }}
                    label="Parameters Count"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" },
                    }}
                    variant="outlined"
                    value={numParams}
                    onChange={(e) => handleChange("numParams", e)}
                    required
                  />
                </Col>
                <Col md={1}></Col>
                <Col md={1} className="flex" style={{}}>
                  <span className="pt-1">Inactive</span>
                  <Switch
                    disabled={showResponse}
                    checked={activeChecked}
                    onChange={handleSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span className="pt-1">Active</span>
                </Col>
                <Col md={5}></Col>
              </Row>

              <Row style={{ margin: "30px" }}>
                <Col style={{ paddingRight: "47px" }} md={2}>
                  {" "}
                </Col>
                <Col md={4}>
                  <TextField
                    disabled={showResponse}
                    inputProps={{ "data-testid": "owners" }}
                    sx={{ width: "100%" }}
                    required
                    id="filled-required"
                    label="Owners"
                    type="text"
                    placeholder="comma seperated emails"
                    value={owner}
                    onChange={(e) => handleChange("owner", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                  />
                </Col>
                <Col md={1}></Col>
                <Col md={4}>
                  <TextField
                    disabled={showResponse}
                    inputProps={{ "data-testid": "source" }}
                    sx={{ width: "100%" }}
                    required
                    id="filled-required"
                    label="Source Application"
                    type="text"
                    placeholder="Enter source app name which use this template"
                    value={source === "core-platform" ? "" : source}
                    onChange={(e) => handleChange("source", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                  />
                </Col>
              </Row>

              <Row style={{ margin: "30px" }}>
                <Col style={{ paddingRight: "19px" }} md={2}>
                  {" "}
                </Col>
                <Col md={9}>
                  <TextField
                    disabled={showResponse}
                    inputProps={{ "data-testid": "subject" }}
                    style={{ width: "100%" }}
                    required
                    id="filled-description"
                    label="Description"
                    value={subject}
                    onChange={(e) => handleChange("subject", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                  />
                </Col>
              </Row>

              <Row style={{ margin: "30px" }}>
                <Col style={{ paddingLeft: "58px" }} md={2}>
                  {" "}
                </Col>
                <Col md={9}>
                  <TextField
                    disabled={restrictEditing}
                    inputProps={{ "data-testid": "templateMessage" }}
                    style={{ width: "100%" }}
                    required
                    id="filled-message"
                    multiline
                    rows={3}
                    label="Template Message"
                    value={templateMessage}
                    onChange={(e) => handleChange("templateMessage", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                  />
                </Col>
              </Row>
            </fieldset>
            <Row>
              <Col md={7}></Col>
              {!isCreate && (
                <Col md={1} style={{ paddingLeft: "170px" }}>
                  <Button
                    color="error"
                    onClick={(e) =>
                      showDeleteConfirmationPopUp(e, templateIdToDelete)
                    }
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </Col>
              )}

              {!isCreate ? (
                <Col md={1} style={{ paddingLeft: "100px" }}>
                  <Button
                    disabled={showResponse}
                    inputprops={{ "data-testid": "update" }}
                    color="success"
                    type="submit"
                    variant="outlined"
                    startIcon={<UpdateIcon />}
                  >
                    Update
                  </Button>
                </Col>
              ) : (
                <Col md={1} style={{ paddingLeft: "280px" }}>
                  <Button
                    disabled={showResponse}
                    inputprops={{ "data-testid": "create" }}
                    color="success"
                    type="submit"
                    variant="outlined"
                    startIcon={<CreateIcon />}
                  >
                    Create
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        )}
        {channel === "EMAIL" && (
          <Form
            onSubmit={
              isCreate
                ? (e) => showCreateConfirmationPopup(e)
                : (e) => showUpdateConfirmationPopUp(e, templateIdToUpdate)
            }
          >
            <fieldset>
              <Row>
                <Col style={{ paddingLeft: "76px" }} md={2}>
                  {" "}
                </Col>
                <Col style={{ textAlign: "left", paddingLeft: "42px" }} md={4}>
                  <TextField
                    disabled={showResponse}
                    id="filled-number"
                    sx={{ width: "100%" }}
                    inputProps={{ "data-testid": "numParams" }}
                    label="Parameters Count"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "black" },
                    }}
                    variant="outlined"
                    value={numParams}
                    onChange={(e) => handleChange("numParams", e)}
                    required
                  />
                </Col>
                <Col md={1}></Col>
                <Col md={1} className="flex" style={{}}>
                  <span className="pt-1">Inactive</span>
                  <Switch
                    disabled={showResponse}
                    checked={activeChecked}
                    onChange={handleSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span className="pt-1">Active</span>
                </Col>
                <Col md={5}></Col>
              </Row>
              <Row style={{ margin: "30px" }}>
                <Col style={{ paddingRight: "45px" }} md={2}>
                  {" "}
                </Col>
                <Col md={4}>
                  <TextField
                    disabled={showResponse}
                    inputProps={{ "data-testid": "owners" }}
                    sx={{ width: "100%" }}
                    required
                    id="filled-required"
                    label="Owners"
                    type="text"
                    placeholder="comma seperated emails"
                    value={owner}
                    onChange={(e) => handleChange("owner", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                  />
                </Col>
                <Col md={1}></Col>
                <Col md={4}>
                  <TextField
                    disabled={showResponse}
                    inputProps={{ "data-testid": "source" }}
                    sx={{ width: "100%" }}
                    required
                    id="filled-required"
                    label="Source Application"
                    type="text"
                    placeholder="Enter source app name which use this template"
                    value={source === "core-platform" ? "" : source}
                    onChange={(e) => handleChange("source", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                  />
                </Col>
              </Row>

              <Row style={{ margin: "30px" }}>
                <Col style={{ paddingRight: "44px" }} md={2}>
                  {" "}
                </Col>
                <Col md={9}>
                  <TextField
                    disabled={showResponse}
                    inputProps={{ "data-testid": "subject" }}
                    style={{ width: "100%" }}
                    required
                    id="filled-description"
                    label="Subject"
                    value={subject}
                    onChange={(e) => handleChange("subject", e)}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                  />
                </Col>
              </Row>

              <Row style={{ margin: "30px" }}>
                <Col style={{ paddingLeft: "21px" }} md={2}>
                  {" "}
                </Col>
                <Col md={9}>
                  <TextField
                    disabled={showResponse}
                    required={isCreate === true}
                    inputProps={{ "data-testid": "templateFile" }}
                    style={{ width: "100%" }}
                    id="filled-description"
                    type="file"
                    onChange={onFileChange}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    variant="outlined"
                    helperText="Upload the Email Template File"
                    FormHelperTextProps={{
                      style: { color: "black" },
                    }}
                  />
                </Col>
              </Row>
            </fieldset>
            <Row style={{ paddingLeft: "190px" }}>
              <Col md={4}></Col>
              {!isCreate ? (
                <Col md={3}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleDownload}
                    startIcon={<DownloadSharpIcon />}
                  >
                    Download Template File
                  </Button>
                </Col>
              ) : (
                <Col md={3}> </Col>
              )}

              {!isCreate ? (
                <Col style={{ paddingLeft: "75px" }} md={1}>
                  <Button
                    style={{ margin: "0px -20px" }}
                    color="error"
                    onClick={(e) =>
                      showDeleteConfirmationPopUp(e, templateIdToDelete)
                    }
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </Col>
              ) : (
                <Col md={1}> </Col>
              )}
              {isCreate && (
                <Col md={1} style={{ paddingLeft: "5px" }}>
                  <Button
                    disabled={showResponse}
                    inputprops={{ "data-testid": "preview" }}
                    onClick={() => {
                      handlePreview();
                    }}
                    variant="outlined"
                  >
                    Preview
                  </Button>
                </Col>
              )}

              {!isCreate && (
                <Col style={{ paddingLeft: "110px" }} md={1}>
                  <Button
                    data-testid="upsert"
                    color="success"
                    type="submit"
                    variant="outlined"
                    startIcon={<UpdateIcon />}
                  >
                    Update
                  </Button>
                </Col>
              )}
              {isCreate && (
                <Col style={{ paddingLeft: "18px" }} md={1}>
                  <Button
                    disabled={showResponse}
                    data-testid="upsert"
                    color="success"
                    type="submit"
                    variant="outlined"
                    startIcon={<CreateIcon />}
                  >
                    Create
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        )}

        {showDeleteConfirmationPopUp(null, null)}
        {showUpdateConfirmationPopUp(null, null)}
        {showCreateConfirmationPopUp()}

        {getSuccess()}
        {getError()}
      </Container>

      {isPreview && (
        <div className=" bg-blue-100 mt-10" style={{}}>
          <div className="mt-10" style={{}}>
            <FreeMarkerTemplatePlayground message={htmlBodyText} />
          </div>
        </div>
      )}
    </>
  );
}

export default GetTemplateForm;
