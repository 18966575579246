import CreateIcon from "@mui/icons-material/Create";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TuneIcon from "@mui/icons-material/Tune";
import KeyIcon from "@mui/icons-material/Key";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import { properties } from "../../properties";

import Cookies from "universal-cookie";
const cookies = new Cookies();
const menuBar = [
  {
    text: "Template",
    icon: <CreateIcon />,
    subList: [
      {
        text: "Manage Template",
        icon: <ManageAccountsIcon />,
        subList: [],
      },
      {
        text: "Preview Template",
        icon: <RemoveRedEyeIcon />,
        subList: [],
      },
    ],
  },
  {
    text: "Track Status",
    icon: <LocalShippingIcon />,
    subList: [],
  },
  {
    text: "Send Notification",
    icon: <NotificationsActiveIcon />,
    subList: [],
  },
  {
    text: "Scheduled Notifications",
    icon: <ScheduleSendIcon />,
    subList: [],
  },
];
if (cookies.get(properties.GOOGLE_IS_ADMIN_STATUS) !== "false") {
  menuBar.push({
    text: "Control Panel",
    icon: <TuneIcon />,
    subList: [
      {
        text: "Rate Limiting",
        icon: <SettingsAccessibilityIcon />,
        subList: [],
      },
      {
        text: "Health Check",
        icon: <MedicalInformationIcon />,
        subList: [],
      },
      {
        text: "App Owner Details",
        icon: <AccountCircleIcon />,
        subList: [],
      },
      {
        text: "App Config",
        icon: <KeyIcon />,
        subList: [],
      },
    ],
  });
}

const routeNameToLinkMap = new Map();
routeNameToLinkMap.set("Track Status", "searchNotificationStatus");
routeNameToLinkMap.set("Manage Template", "template/get");
routeNameToLinkMap.set("Preview Template", "template/preview");
routeNameToLinkMap.set("Rate Limiting", "rateLimit");
routeNameToLinkMap.set("Health Check", "healthCheckConfig");
routeNameToLinkMap.set("App Owner Details", "applicationOwnerDetails");
routeNameToLinkMap.set("Send Notification", "sendNotification");
routeNameToLinkMap.set("App Config", "applicationConfiguration");
routeNameToLinkMap.set("Scheduled Notifications", "scheduledNotifications");

function getByValue(map, searchValue) {
  for (let [key, value] of map.entries()) {
    if (value === searchValue) return key;
  }
  return "";
}
const getNameFromRoute = (text) => {
  return getByValue(routeNameToLinkMap, text);
};
const getRouteFromName = (text) => {
  if (routeNameToLinkMap.has(text)) {
    return "/" + routeNameToLinkMap.get(text);
  }
};
export { menuBar, getNameFromRoute, getRouteFromName };
