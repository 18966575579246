const COUNTRIES = [
  {
    name: "Qatar",
    code: "QA",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+974",
  },
  {
    name: "Kuwait",
    code: "KW",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+965",
  },
  {
    name: "Bahrain",
    code: "BH",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+973",
  },
  {
    name: "Oman",
    code: "OM",
    timezone: "Arabian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+968",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    timezone: "Arabian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+971",
  },
  {
    name: "Nepal",
    code: "NP",
    timezone: "Nepal Standard Time",
    utc: "UTC+05:45",
    mobileCode: "+977",
  },
  {
    name: "Nigeria",
    code: "NG",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+234",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    timezone: "Sri Lanka Standard Time",
    utc: "UTC+05:30",
    mobileCode: "+94",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+966",
  },
  {
    name: "Australia",
    code: "AU",
    timezone: "AUS Eastern Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+61",
  },
  {
    name: "Benin",
    code: "BJ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+229",
  },
  {
    name: "Canada",
    code: "CA",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1",
  },
  {
    name: "Central African Republic",
    code: "CF",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+236",
  },
  {
    name: "Congo (DRC)",
    code: "CD",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+243",
  },
  {
    name: "Ethiopia",
    code: "ET",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+251",
  },
  {
    name: "Germany",
    code: "DE",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+49",
  },
  {
    name: "Haiti",
    code: "HT",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+509",
  },
  {
    name: "Indonesia",
    code: "ID",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+62",
  },
  {
    name: "Malaysia",
    code: "MY",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+60",
  },
  {
    name: "Netherlands",
    code: "NL",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+31",
  },
  {
    name: "New Zealand",
    code: "NZ",
    timezone: "New Zealand Standard Time",
    utc: "UTC+12:00",
    mobileCode: "+64",
  },
  {
    name: "Russia",
    code: "RU",
    timezone: "Russian Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+7",
  },
  {
    name: "Singapore",
    code: "SG",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+65",
  },
  {
    name: "South Africa",
    code: "ZA",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+27",
  },
  {
    name: "Thailand",
    code: "TH",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+66",
  },
  {
    name: "United Kingdom",
    code: "GB",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44",
  },
  {
    name: "United States",
    code: "US",
    timezone: "Pacific Standard Time",
    utc: "UTC-08:00",
    mobileCode: "+1",
  },
  {
    name: "Vietnam",
    code: "VN",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+84",
  },
  {
    name: "Zambia",
    code: "ZM",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+260",
  },
  {
    name: "Bangladesh",
    code: "BD",
    timezone: "Bangladesh Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+880",
  },
  {
    name: "Pakistan",
    code: "PK",
    timezone: "Pakistan Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+92",
  },
  {
    name: "Bhutan",
    code: "BT",
    timezone: "Bangladesh Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+975",
  },
  {
    name: "India",
    code: "IN",
    timezone: "India Standard Time",
    utc: "UTC+05:30",
    mobileCode: "+91",
  },
];
const NAV_BAR_WITHOUT_LOGIN = [
  {
    iconName: "home",
    name: "Home",
    value: "",
  },
];
const NAV_BAR_WITH_LOGIN = [
  {
    iconName: "template",
    name: "Template",
    value: "template/get",
  },
  {
    iconName: "trackStatus",
    name: "Tracking Status",
    value: "searchNotificationStatus",
  },
];

const ADMIN_USERS = process.env.REACT_APP_ADMIN_USERS?.split(",") || [];

const OPERATORS_SYMBOL_MAP = new Map([
  ["EQUALS", "="],
  ["NOT_EQUALS", "!="],
  ["GREATER_THAN", ">"],
  ["LESS_THAN", "<"],
  ["GREATER_THAN_OR_EQUAL_TO", ">="],
  ["LESS_THAN_OR_EQUAL_TO", "<="],
  ["CONTAINS", "contains"],
  ["DOES_NOT_CONTAIN", "doesn't_contain"],
  ["=", "EQUALS"],
  ["!=", "NOT_EQUALS"],
  [">", "GREATER_THAN"],
  ["<", "LESS_THAN"],
  [">=", "GREATER_THAN_OR_EQUAL_TO"],
  ["<=", "LESS_THAN_OR_EQUAL_TO"],
  ["contains", "CONTAINS"],
  ["doesn't_contain", "DOES_NOT_CONTAIN"],
]);
const RATE_LIMIT_COLUMN_PROPERTIES_LIST = [
  {
    label: "Config",
    column: "config",
    minWidth: "104px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: true,
    type: "text",
  },
  {
    label: "Max Requests",
    column: "maxRequests",
    minWidth: "149px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: false,
    type: "number",
  },
  {
    label: "Max requests to block",
    column: "maxRequestsToBlock",
    minWidth: "201px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: false,
    type: "number",
  },
  {
    label: "Rate Duration(sec)",
    column: "rateDurationInSeconds",
    minWidth: "182px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: false,
    type: "number",
  },
  {
    label: "Lock Duration(sec)",
    column: "lockDurationInSeconds",
    minWidth: "181px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: false,
    type: "number",
  },
  {
    label: "Owners",
    column: "owners",
    minWidth: "121px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
    type: "email",
  },
  {
    label: "Start Time",
    column: "startTime",
    minWidth: "123px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: false,
    type: "datetime-local",
  },
  {
    label: "End Time",
    column: "endTime",
    minWidth: "123px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: false,
    type: "datetime-local",
  },
  {
    label: "Actions",
    column: " actions",
    minWidth: "130px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
  },
];
const APPLICATION_OWNERS_COLUMN_PROPERTIES_LIST = [
  {
    label: "Name",
    column: "name",
    minWidth: "104px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: true,
    type: "text",
  },
  {
    label: "Hydra Client Id",
    column: "hydraClientId",
    minWidth: "149px",
    isSortable: false,
    isFilterable: true,
    isTextColumn: true,
    type: "text",
  },
  {
    label: "Owners",
    column: "owners",
    minWidth: "196px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
    type: "email",
  },
  {
    label: "Created By",
    column: "createdBy",
    minWidth: "177px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
    type: "email",
  },
  {
    label: "Created At",
    column: "createdAt",
    minWidth: "177px",
    isSortable: true,
    isFilterable: false,
    isTextColumn: false,
    type: "datetime-local",
  },
  {
    label: "Updated By",
    column: "updatedBy",
    minWidth: "177px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
    type: "email",
  },
  {
    label: "Updated At",
    column: "updatedAt",
    minWidth: "177px",
    isSortable: true,
    isFilterable: false,
    isTextColumn: false,
    type: "datetime-local",
  },
  {
    label: "Owners",
    column: "owners",
    minWidth: "121px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
    type: "email",
  },
  {
    label: "Actions",
    column: " actions",
    minWidth: "130px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
  },
];
const HEALTH_CHECK_COLUMN_PROPERTIES_LIST = [
  {
    label: "URL",
    column: "url",
    minWidth: "104px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: true,
    type: "text",
  },
  {
    label: "Http Method",
    column: "httpMethod",
    minWidth: "149px",
    isSortable: false,
    isFilterable: true,
    isTextColumn: true,
    type: "text",
  },
  {
    label: "Expected Status Code",
    column: "expectedStatus",
    minWidth: "202px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: false,
    type: "number",
  },
  {
    label: "Owners",
    column: "owners",
    minWidth: "121px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
    type: "email",
  },
  {
    label: "Start Time",
    column: "startTime",
    minWidth: "123px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: false,
    type: "datetime-local",
  },
  {
    label: "End Time",
    column: "endTime",
    minWidth: "123px",
    isSortable: true,
    isFilterable: true,
    isTextColumn: false,
    type: "datetime-local",
  },
  {
    label: "Created By",
    column: "createdBy",
    minWidth: "177px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
    type: "email",
  },
  {
    label: "Created At",
    column: "createdAt",
    minWidth: "177px",
    isSortable: true,
    isFilterable: false,
    isTextColumn: false,
    type: "datetime-local",
  },
  {
    label: "Updated By",
    column: "updatedBy",
    minWidth: "177px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
    type: "email",
  },
  {
    label: "Updated At",
    column: "updatedAt",
    minWidth: "177px",
    isSortable: true,
    isFilterable: false,
    isTextColumn: false,
    type: "datetime-local",
  },
  {
    label: "Actions",
    column: " actions",
    minWidth: "130px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
  },
];
const APPLICATION_CONFIGURATION_COLUMN_PROPERTIES_LIST = [
  {
    label: "Key",
    column: "key",
    minWidth: "250px",
    isSortable: false,
    isFilterable: true,
    isTextColumn: true,
    type: "text",
  },
  {
    label: "Value",
    column: "value",
    minWidth: "400px",
    isSortable: false,
    isFilterable: true,
    isTextColumn: true,
    type: "text",
  },
  {
    label: "Version",
    column: "version",
    minWidth: "70px",
    isSortable: false,
    isFilterable: true,
    isTextColumn: false,
    type: "number",
  },
  {
    label: "Created By",
    column: "createdBy",
    minWidth: "177px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
    type: "email",
  },
  {
    label: "Created At",
    column: "createdAt",
    minWidth: "177px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
    type: "datetime-local",
  },
  {
    label: "Actions",
    column: " actions",
    minWidth: "130px",
    isSortable: false,
    isFilterable: false,
    isTextColumn: false,
  },
];

const SCHEDULED_NOTIFICATIONS_COLUMN_PROPERTIES_LIST = [
  {
    label: "scheduled time",
    column: "deliveryTime",
    minWidth: "104px",
  },
  {
    label: "trackingid",
    column: "trackingId",
    minWidth: "149px",
  },
  {
    label: "templateid",
    column: "templateId",
    minWidth: "201px",
  },
  {
    label: "Channel",
    column: "channel",
    minWidth: "182px",
  },
  {
    label: "numberOfUsers",
    column: "numberOfUsers",
    minWidth: "182px",
  },
  {
    label: "Actions",
    column: " actions",
    minWidth: "130px",
  },
];

export {
  COUNTRIES,
  NAV_BAR_WITHOUT_LOGIN,
  NAV_BAR_WITH_LOGIN,
  ADMIN_USERS,
  OPERATORS_SYMBOL_MAP,
  RATE_LIMIT_COLUMN_PROPERTIES_LIST,
  APPLICATION_OWNERS_COLUMN_PROPERTIES_LIST,
  HEALTH_CHECK_COLUMN_PROPERTIES_LIST,
  APPLICATION_CONFIGURATION_COLUMN_PROPERTIES_LIST,
  SCHEDULED_NOTIFICATIONS_COLUMN_PROPERTIES_LIST,
};
