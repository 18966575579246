import { useEffect, useRef, useState } from "react";
import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import { previewTemplate } from "../Api/FreeMarkerTemplatePlaygroundApi";

function FreeMarkerTemplatePlayground({ message }) {
  const defaultHeightForText = "400px";
  const defaultHeightForParams = "200px";
  const defaultHeightForResult = "650px";
  const defaultText = `<!DOCTYPE html>
<html lang="en">
<head>
    <style>
        table {
            border-collapse: collapse;
        }

        td, th {
            border: 1px solid #dddddd;
            text-align: center;
            padding: 8px;
        }

        tr:nth-child(even) {
            background-color: #dddddd;
        }
    </style>
</head>
<body>
<h2>This is a sample template file with some variables.</h2>
<h3>Variables can be primitives.</h3>
<table>
    <tr>
        <td>String</td>
        <td>\${String}</td>
    </tr>
    <tr>
        <td>Integer</td>
        <td>\${Integer}</td>
    </tr>
    <tr>
        <td>Variable with default value</td>
        <td>\${newVariable!"Default Value"}</td>
    </tr>
    <tr>
        <td>Conditional values</td>
        <td>
          <#if Variable??>
            Some value provided for "Variable" param
          <#else>
            No value provided for "Variable" param
          </#if>
        </td>
    </tr>
</table>
<h3>Variables can be more complex JSONs.</h3>
<table>
    <tr>
        <th>Key</th>
        <th>value</th>
    </tr>
    <#list data as entry>
    <tr>
        <td>\${entry.name}</td>
        <td>\${entry.value!"Default Value"}</td>
    </tr>
</#list>
</table>
</body>
</html>`;
  const [templateText, setTemplateText] = useState(
    message !== undefined ? message : defaultText
  );

  const [templateParams, setTemplateParams] = useState(`{
"String": "Hello",
"Integer": 100,
"data": [
    {
    "name": "String type variable",
    "value": "Hello, world"
    },
    {
    "name": "Integer type variable",
    "value": 100
    }
]
}`);
  const [previewTemplateResult, setPreviewTemplateResult] = useState("");
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    if (!renderAfterCalled.current) {
      document.getElementById("previewTemplateButtonId").click();
    }
    renderAfterCalled.current = true;
  }, []);

  async function handleSubmit(event) {
    try {
      event.preventDefault();
      const response = await previewTemplate(templateText, templateParams);
      if (response.data != null && response.data["errorMessage"] != null) {
        setPreviewTemplateResult("Error: " + response.data["errorMessage"]);
      } else {
        setPreviewTemplateResult(String(response.data.result));
      }
    } catch (error) {
      setPreviewTemplateResult(error.message);
    }
  }

  function handleChange(variable, event) {
    switch (variable) {
      case "templateText":
        setTemplateText(event.target.value);
        break;
      case "templateParams":
      default:
        setTemplateParams(event.target.value);
        break;
    }
  }

  function updatePreview() {
    return (
      <div style={{ display: "flex", width: "100%", margin: "10px" }}>
        <ul>
          <strong>Please check the preview of the template below</strong>
          <iframe
            srcDoc={previewTemplateResult}
            title="Preview of the result"
            style={{
              flexGrow: "1",
              backgroundColor: "white",
              height: defaultHeightForResult,
              width: "100%",
            }}
          ></iframe>
        </ul>
      </div>
    );
  }

  return (
    <>
      <Container
        style={{ display: "flex", paddingTop: "110px", paddingLeft: "240px" }}
      >
        <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <fieldset>
            <Form.Group className="mb-3">
              <Form.Label id="freemarkerHelp">
                For more information on syntaxes, please refer{" "}
                <a
                  href="https://freemarker.apache.org/docs/ref.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  the official documentation of FreeMarker
                </a>
                .
              </Form.Label>
              <FloatingLabel
                label="Enter the template text here"
                style={{ marginBottom: "10px" }}
              >
                <Form.Control
                  style={{ height: defaultHeightForText, marginTop: "10px" }}
                  as="textarea"
                  id="templateTextId"
                  value={templateText}
                  data-testid="templateTextId"
                  onChange={(e) => handleChange("templateText", e)}
                />
              </FloatingLabel>
              <FloatingLabel
                label="Enter the valid template variables JSON here"
                className="mb-3"
              >
                <Form.Control
                  style={{ height: defaultHeightForParams }}
                  as="textarea"
                  type="json"
                  id="templateParamsId"
                  data-testid="templateParamsId"
                  value={templateParams}
                  onChange={(e) => handleChange("templateParams", e)}
                />
              </FloatingLabel>
            </Form.Group>
            <Button
              id="previewTemplateButtonId"
              type="submit"
              data-testid="previewTemplateButtonId"
            >
              Preview
            </Button>
          </fieldset>
        </Form>
        {updatePreview()}
      </Container>
    </>
  );
}

export default FreeMarkerTemplatePlayground;
