import "../css/login.css";
import byjusLogo from "../assets/svg/sidebar/byjuslogo.svg";
import universalCookies from "universal-cookie";
import { Navigate } from "react-router-dom";
import { properties } from "../properties";
import { useEffect, useRef } from "react";
import GoogleIcon from "@mui/icons-material/Google";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import * as React from "react";

function LoginPage({ isAdmin }) {
  // eslint-disable-next-line no-undef
  const cookies = new universalCookies();
  const initialPageLoad = useRef(false);
  const loginButtonRef = useRef(null);

  useEffect(() => {
    if (!initialPageLoad.current) {
      initialPageLoad.current = true;
      // if refresh token is available, click it automatically
      const cookies = new universalCookies();
      if (cookies.get(properties.GOOGLE_REFRESH_TOKEN_METADATA_COOKIE)) {
        loginButtonRef.current.click();
      }
    }
  }, []);

  const handleLogin = async () => {
    const url =
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_LOGIN_URL;
    cookies.set(
      properties.HOST_URL_COOKIE,
      process.env.REACT_APP_REDIRECT_URL,
      {
        path: "/",
        secure: true,
        domain: process.env.REACT_APP_DOMAIN_NAME,
      }
    );
    window.location.assign(url);
  };

  if (cookies.get(properties.GOOGLE_USER_DETAILS_COOKIE)) {
    return <Navigate replace to="/" />;
  } else {
    return (
      <div className="bg_style">
        <div className="left_half">
          <div className="ellipse_bg">
            <div className="ellipse_round"></div>
            <div className="ellipse_semicircle"></div>
          </div>
        </div>

        <div className="right_half">
          <div className="byjus_logo">
            <img src={byjusLogo} alt="Byjus logo" />
          </div>

          <div className="flex flex-col">
            <div className="h-10"></div>
            <Typography variant="h3" color={"text.primary"}>
              Welcome!
            </Typography>
            <Typography variant="h5" color={"grey"}>
              Please sign in through your company mail
            </Typography>

            <div
              style={{
                fontSize: "1.5em",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
              }}
              className={"my-3"}
            >
              <Button
                variant="contained"
                startIcon={<GoogleIcon />}
                onClick={handleLogin}
                ref={loginButtonRef}
              >
                Sign in with Google
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LoginPage;
