import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import InsertTemplateVar from "./InsertTemplateVar";
import { nanoid } from "@reduxjs/toolkit";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PhoneInput from "react-phone-input-2";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { COUNTRIES } from "../Utils/constants";
import { Tooltip } from "react-bootstrap";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";

const AddPhoneTemplatePopup = ({
  receiverVar,
  addRecieverTemplatePopupHandler,
  channel,
  updateRecieverVarList,
  uploadedCSV,
  setCountry,
}) => {
  const [recieverVarList, setRecieverVarList] = useState(receiverVar);
  const usersToDisplay = process.env.REACT_APP_TOTAL_USERS_TO_DISPLAY;

  const handleAddRecieverVarClick = () => {
    const recieverVarListTemp = recieverVarList.map((recieverVarListItem) =>
      Object.assign({}, recieverVarListItem, recieverVarListItem)
    );

    if (recieverVarListTemp.length !== 0) {
      const emptyVarList = recieverVarListTemp[0].varList.map((varItem) => ({
        ...varItem,
        value: "",
        id: nanoid(),
      }));

      const emptyRecieverList = {};
      if (recieverVarListTemp[0].reciever !== undefined)
        emptyRecieverList["reciever"] = "";
      if (recieverVarListTemp[0].countryCode !== undefined)
        emptyRecieverList["countryCode"] = "";
      if (recieverVarListTemp[0].country !== undefined)
        emptyRecieverList["country"] = "";
      if (recieverVarListTemp[0].premiumUserId !== undefined)
        emptyRecieverList["premiumUserId"] = "";
      if (recieverVarListTemp[0].freeUserId !== undefined)
        emptyRecieverList["freeUserId"] = "";
      emptyRecieverList["varList"] = emptyVarList;

      recieverVarListTemp.unshift(emptyRecieverList);
    } else {
      recieverVarListTemp.push({
        reciever: "",
        varList: [],
        countryCode: "",
        country: "",
      });
    }
    setRecieverVarList(recieverVarListTemp);
  };

  const handleRecieverPhoneValChange = (value, country, index) => {
    setCountry(country.countryCode.toUpperCase());
    const recieverVarListTemp = recieverVarList.map((recieverVarListItem) =>
      Object.assign({}, recieverVarListItem, recieverVarListItem)
    );
    recieverVarListTemp[index].reciever = value;
    recieverVarListTemp[index].countryCode = "+" + country.dialCode;
    recieverVarListTemp[index].country = country.countryCode.toUpperCase();
    setRecieverVarList(recieverVarListTemp);
  };

  const handleRecieverEmailValChange = (e, index) => {
    const recieverVarListTemp = recieverVarList.map((recieverVarListItem) =>
      Object.assign({}, recieverVarListItem, recieverVarListItem)
    );
    recieverVarListTemp[index].reciever = e.target.value;
    setRecieverVarList(recieverVarListTemp);
  };

  const handlePremiumOrFreeUserIdChange = (e, index, isPremiumId) => {
    const recieverVarListTemp = recieverVarList.map((recieverVarListItem) =>
      Object.assign({}, recieverVarListItem, recieverVarListItem)
    );
    if (isPremiumId === true) {
      recieverVarListTemp[index].premiumUserId = e.target.value;
    } else {
      recieverVarListTemp[index].freeUserId = e.target.value;
    }
    setRecieverVarList(recieverVarListTemp);
  };

  const handleAddMoreVarClick = (index) => {
    const recieverVarListTemp = recieverVarList.map((recieverVarListItem) =>
      Object.assign({}, recieverVarListItem, recieverVarListItem)
    );
    recieverVarListTemp[index].varList.push({
      key: "",
      value: "",
      id: nanoid(),
    });
    setRecieverVarList(recieverVarListTemp);
  };

  const handleCloseVarClick = (index1, index2) => {
    const recieverVarListTemp = recieverVarList.map((recieverVarListItem) =>
      Object.assign({}, recieverVarListItem, recieverVarListItem)
    );
    recieverVarListTemp[index1].varList.splice(index2, 1);
    setRecieverVarList(recieverVarListTemp);
  };

  const handleVarDataChange = (oldData, index1, index2) => {
    const recieverVarListTemp = recieverVarList.map((recieverVarListItem) =>
      Object.assign({}, recieverVarListItem, recieverVarListItem)
    );
    recieverVarListTemp[index1].varList[index2] = {
      key: oldData.key.trim(),
      value: oldData.value.trim(),
      id: recieverVarListTemp[index1].varList[index2].id,
    };
    setRecieverVarList(recieverVarListTemp);
  };

  const receiverDesc = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Enter Mobile Number
    </Tooltip>
  );

  const handleSaveClick = () => {
    updateRecieverVarList(recieverVarList);
    addRecieverTemplatePopupHandler();
  };

  const handleCloseClick = () => {
    updateRecieverVarList(receiverVar);
    addRecieverTemplatePopupHandler();
  };

  return (
    <div className="fixed bg-black inset-0 bg-opacity-10 flex justify-center items-center z-10 backdrop-blur-sm">
      <div className="bg-white pl-9 rounded-lg p-6 w-1/2 max-h-screen overflow-scroll">
        <div className="flex">
          <div className="flex-1 flex font-semibold text-xl text-text_meduimGrey	items-start w-fit h-fit ">
            {!uploadedCSV
              ? "Add Reciever with Template Variable"
              : "Uploaded data through CSV"}
          </div>
          <div className="flex">
            <CloseIcon
              data-testid="close-icon"
              onClick={addRecieverTemplatePopupHandler}
              fontSize="small"
              className="hover:cursor-pointer  hover:text-text_darkGrey p-1 bg-text_lighterGrey rounded-xl text-text_grey"
            />
          </div>
        </div>
        <div className="mt-px  md:mt-0 overflow-hidden">
          <div className="bg-white py-3 ">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-7">
                <label
                  htmlFor="role-name"
                  className="my-2 text-sm flex text-text_meduimGrey"
                >
                  {" "}
                  {recieverVarList.length} Reciever(s) with Variable(s) added{" "}
                  {recieverVarList.length > usersToDisplay &&
                    `but displaying only ${usersToDisplay}`}
                </label>
                <button
                  style={{ marginBottom: "8px" }}
                  type="button"
                  className="mt-2 text-primary text-body2 flex hover:cursor-pointer"
                  onClick={handleAddRecieverVarClick}
                >
                  + Add more Recievers with Variables
                </button>
                {recieverVarList
                  .slice(0, usersToDisplay)
                  .map((recieverVarListItem, index1) => (
                    <div key={index1}>
                      {recieverVarListItem.premiumUserId !== undefined ||
                      recieverVarListItem.freeUserId !== undefined ? (
                        <TextField
                          style={{ margin: "0px" }}
                          inputProps={{ "data-testid": "Id" }}
                          sx={{ width: "100%" }}
                          required
                          label={
                            recieverVarListItem.premiumUserId !== undefined
                              ? "Premium User Id"
                              : "Free User Id"
                          }
                          value={
                            recieverVarListItem.premiumUserId !== undefined
                              ? recieverVarListItem.premiumUserId
                              : recieverVarListItem.freeUserId
                          }
                          onChange={(e) =>
                            handlePremiumOrFreeUserIdChange(
                              e,
                              index1,
                              recieverVarListItem.premiumUserId !== undefined
                            )
                          }
                          InputLabelProps={{
                            style: { color: "black" },
                          }}
                          variant="outlined"
                        />
                      ) : channel !== "email" ? (
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={receiverDesc}
                        >
                          <Form.Group as={Col} className="my-3">
                            <PhoneInput
                              inputProps={{ "data-testid": "phone-inputs" }}
                              id="receiverPhone"
                              country={
                                recieverVarListItem.country === ""
                                  ? "in"
                                  : recieverVarListItem.country.toLocaleLowerCase()
                              }
                              name={"receiverPhone"}
                              value={recieverVarListItem.reciever}
                              onlyCountries={COUNTRIES.map((item) =>
                                item.code.toLocaleLowerCase()
                              )}
                              enableSearch={true}
                              onChange={(value, country) =>
                                handleRecieverPhoneValChange(
                                  value,
                                  country,
                                  index1
                                )
                              }
                              inputStyle={{ height: "38px" }}
                            />
                          </Form.Group>
                        </OverlayTrigger>
                      ) : (
                        <TextField
                          style={{ margin: "0px" }}
                          inputProps={{ "data-testid": "reciever-email" }}
                          sx={{ width: "100%" }}
                          required
                          id="emailSender"
                          label="Reciever Email"
                          type="email"
                          value={recieverVarListItem.reciever}
                          onChange={(e) =>
                            handleRecieverEmailValChange(e, index1)
                          }
                          InputLabelProps={{
                            style: { color: "black" },
                          }}
                          variant="outlined"
                        />
                      )}
                      {recieverVarListItem.varList.map(
                        (varListItem, index2) => (
                          <div
                            key={varListItem.id}
                            className="mb-1 grid grid-cols-12"
                          >
                            <div className="col-span-11">
                              <InsertTemplateVar
                                metadata={varListItem}
                                onChange={(e) =>
                                  handleVarDataChange(e, index1, index2)
                                }
                                index={index2}
                              />
                            </div>
                            <div className=" col-span-1  mt-1 text-gray-400 ml-2 w-5 hover:cursor-pointer hover:text-text_darkGrey">
                              <Close
                                data-testid="close-metadata"
                                fontSize="small"
                                onClick={() =>
                                  handleCloseVarClick(index1, index2)
                                }
                              />
                            </div>
                          </div>
                        )
                      )}
                      <button
                        style={{ marginBottom: "8px" }}
                        type="button"
                        className="mt-2 text-primary text-body2 flex hover:cursor-pointer"
                        onClick={() => handleAddMoreVarClick(index1)}
                      >
                        + Add more Variable
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className=" py-3 text-right  flex space-x-2">
            <Button variant="contained" type="submit" onClick={handleSaveClick}>
              Update
            </Button>

            <Button variant="outlined" onClick={handleCloseClick}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPhoneTemplatePopup;
