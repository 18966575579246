import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { Col, Modal, Row, Table, Toast, ToastContainer } from "react-bootstrap";
import SortableTable from "./SortableTable";
import { Pagination, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import FilterDropDownUtil from "./filterDropDownUtil";
import AllFiltersApplied from "./allFiltersAppliedUtil";
import CreateIcon from "@mui/icons-material/Create";
import Container from "react-bootstrap/Container";
import { auditLogEmails } from "../Api/AuditLogEmails.Api";
import Form from "react-bootstrap/Form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ConfirmationPopup from "../Components/Popups/ConfirmationPopup";

export function useCrud(
  heading,
  columnPropertyList,
  getConfigApi,
  updateConfigApi,
  deleteConfigApi,
  createConfigApi,
  getModalBody,
  getConfigObject,
  defaultSortProperty,
  url
) {
  const [existingConfig, setExistingConfig] = useState([]);
  const [showUpsertForm, setShowUpsertForm] = useState(false);
  const [configError, setConfigError] = useState();
  const [configSuccess, setConfigSuccess] = useState("");
  const [showToast, setShowToast] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [isCreate, setIsCreated] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderBy, setOrderBy] = useState("ASC");
  const [property, setProperty] = useState(defaultSortProperty);
  const [column, setColumn] = useState("");
  const [operation, setOperation] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [allFiltersArrayOfObjects, setAllFiltersArrayOfObjects] = useState([]);
  const [allFiltersArrayOfStrings, setAllFiltersArrayOfStrings] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [isSearchTemplateIdLoading, setIsSearchTemplateIdLoading] =
    useState(true);

  async function reloadData(
    pageNumber,
    orderBy,
    property,
    allFiltersArrayOfObjects,
    rowsPerPage
  ) {
    let response;
    if (orderBy !== undefined && column !== undefined) {
      setIsSearchTemplateIdLoading(false);
      response = await getConfigApi(
        url,
        pageNumber,
        orderBy,
        property,
        allFiltersArrayOfObjects,
        (error) => {
          setConfigError(error);
          setShowToast(true);
        },
        rowsPerPage
      );
      setIsSearchTemplateIdLoading(true);
    }

    if (response !== undefined) {
      setExistingConfig(response.data.data);
      setTotalPages(response.data.totalPages);
    }
  }
  function closeUpsertForm() {
    setShowUpsertForm(false);
  }

  function closeDeleteConfirmationPopUp() {
    setShowDeleteConfirmation(false);
  }

  function displayUpsertForm() {
    setShowUpsertForm(true);
  }
  function handleCreate(event) {
    event.preventDefault();
    const configObject = getConfigObject(selectedObject);
    if (configObject["version"] !== undefined) {
      configObject["version"] = configObject["version"] + 1;
      console.log("version to post is", configObject["version"]);
    }
    const create = async () => {
      const response = await createConfigApi(url, configObject, (error) => {
        setConfigError(error);
        setShowToast(true);
      });
      if (response !== undefined) {
        await reloadData(
          currentPage - 1,
          orderBy,
          defaultSortProperty,
          [],
          rowsPerPage
        );

        setConfigSuccess("Successfully created the config.");
        setShowToast(true);

        // so send email to admins as someone created the config
        await auditLogEmails(heading + " ", "created ", response.data, {});
      }
    };
    create().catch(console.error);
    closeUpsertForm();
  }

  function handleUpdate(event) {
    event.preventDefault();
    const configObject = getConfigObject(selectedObject);
    console.log("Config object", JSON.stringify(configObject));
    if (configObject["version"] !== undefined) {
      handleCreate(event);
      return;
    }
    const update = async () => {
      const response = await updateConfigApi(
        url,
        selectedObject["id"],
        configObject,
        (error) => {
          setConfigError(error);
          setShowToast(true);
        }
      );
      if (response !== undefined) {
        await reloadData(
          currentPage - 1,
          orderBy,
          defaultSortProperty,
          allFiltersArrayOfObjects,
          rowsPerPage
        );

        setConfigSuccess("Successfully updated the config.");
        setShowToast(true);

        const updatedConfig = existingConfig.find(
          (obj) => obj.id === selectedObject["id"]
        );

        // so send email to admins as someone updated the config
        await auditLogEmails(
          heading + " ",
          "updated ",
          updatedConfig,
          response.data
        );
      }
    };
    update().catch(console.error);
    closeUpsertForm();
  }
  function handleDelete(event) {
    event.preventDefault();
    const deleteData = async () => {
      const response = await deleteConfigApi(
        url,
        selectedObject["id"],
        (error) => {
          setConfigError(error);
          setShowToast(true);
        }
      );
      if (response !== undefined) {
        await reloadData(
          currentPage - 1,
          orderBy,
          defaultSortProperty,
          allFiltersArrayOfObjects,
          rowsPerPage
        );

        setConfigSuccess("Successfully deleted the config.");
        setShowToast(true);

        const deletedConfig = existingConfig.find(
          (obj) => obj.id === selectedObject["id"]
        );

        // so send email to admins as someone deleted the config
        await auditLogEmails(heading + " ", "deleted ", deletedConfig, {});
      }
    };

    deleteData().catch(console.error);
    closeDeleteConfirmationPopUp();
  }

  function showDeleteConfirmationPopUp(event, id) {
    if (event !== undefined && id !== undefined && id !== null) {
      event.preventDefault();
      handleChange("id", id);
      setShowDeleteConfirmation(true);
    }
    return (
      <ConfirmationPopup
        confirmation={showDeleteConfirmation}
        closeConfirmationPopUp={closeDeleteConfirmationPopUp}
        handleAction={handleDelete}
        action={["delete", "deleting"]}
      />
    );
  }
  function getSuccess() {
    if (configSuccess !== undefined && showToast) {
      return (
        <ToastContainer
          data-testid="success-toast"
          position="bottom-end"
          className="p-3"
        >
          <Toast
            onClose={() => {
              setShowToast(false);
              setConfigSuccess(undefined);
            }}
            show={showToast}
            delay={3000}
            autohide
            className="d-inline-block m-1"
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">
                Request processed successfully!
              </strong>
            </Toast.Header>
            <Toast.Body>{configSuccess}</Toast.Body>
          </Toast>
        </ToastContainer>
      );
    }
  }
  function getError() {
    if (configError !== undefined) {
      return (
        <ToastContainer
          data-testid="error-toast"
          position="bottom-end"
          className="p-3"
        >
          <Toast
            onClose={() => {
              setShowToast(false);
              setConfigError(undefined);
            }}
            show={showToast}
            delay={3000}
            autohide
            className="d-inline-block m-1"
            bg="danger"
          >
            <Toast.Header>
              <strong className="me-auto">Oh snap! You got an error!</strong>
            </Toast.Header>
            <Toast.Body>{String(configError)}</Toast.Body>
          </Toast>
        </ToastContainer>
      );
    }
  }
  function handleUpsert(event, config) {
    event.preventDefault();
    if (config !== null) {
      setSelectedObject((selectedObject) => config);
      setIsCreated(false);
    } else {
      setSelectedObject((selectedObject) => {});
      setIsCreated(true);
    }
    displayUpsertForm();
  }
  async function handlePagination(pageNumber) {
    setCurrentPage(pageNumber);
    await reloadData(
      pageNumber - 1,
      orderBy,
      property,
      allFiltersArrayOfObjects,
      rowsPerPage
    );
  }
  function handleChange(column, value) {
    let tempObject = selectedObject;
    if (tempObject === undefined) {
      tempObject = {};
    }
    tempObject[column] = value;
    setSelectedObject({ ...tempObject });
  }

  const getTimeDifferenceFromNow = (time) => {
    var date = new Date(time);
    return date.toISOString().substring(0, 23);
  };

  function getUpsertPopup() {
    return (
      <Modal
        backdrop="static"
        centered
        show={showUpsertForm}
        onHide={closeUpsertForm}
      >
        <Modal.Header closeButton>
          <Modal.Title textalign="center">
            {isCreate ? "Add a new entry" : "Update the entry"}
          </Modal.Title>
        </Modal.Header>
        <Form
          style={{ display: "block", textAlign: "center" }}
          onSubmit={(e) => (isCreate ? handleCreate(e) : handleUpdate(e))}
        >
          <fieldset>
            {getModalBody(
              handleChange,
              selectedObject,
              getTimeDifferenceFromNow,
              isCreate
            )}
            <Modal.Footer>
              <Button
                data-testid="upsert"
                variant="outlined"
                color="success"
                type="submit"
              >
                {isCreate ? "Create" : "Update"}
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                variant="outlined"
                onClick={closeUpsertForm}
              >
                Close
              </Button>
            </Modal.Footer>
          </fieldset>
        </Form>
      </Modal>
    );
  }
  async function handleChangeRowsPerPage(event) {
    event.preventDefault();
    const rows = event.target.value;
    setRowsPerPage(rows);

    await reloadData(0, orderBy, property, allFiltersArrayOfObjects, rows);
  }

  function getPagination() {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ flex: "5%" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Rows</InputLabel>
            <Select
              data-testid="rowscolumn-select"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rowsPerPage}
              label="Rows"
              onChange={(e) => handleChangeRowsPerPage(e)}
            >
              <MenuItem value={"10"}>10</MenuItem>
              <MenuItem value={"25"}>25</MenuItem>
              <MenuItem value={"50"}>50</MenuItem>
              <MenuItem value={"100"}>100</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div style={{ flex: "95%" }}>
          <Pagination
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
            count={totalPages}
            data-testid="pagination"
            color="primary"
            page={currentPage}
            onChange={(e, page) => handlePagination(page)}
          />
        </div>
      </div>
    );
  }

  function getFilterDropDownUtil() {
    return (
      <FilterDropDownUtil
        orderBy={orderBy}
        property={property}
        column={column}
        setColumn={setColumn}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        operation={operation}
        setOperation={setOperation}
        textOperations={columnPropertyList
          .filter((item) => item.isTextColumn)
          .map((item) => item.column)}
        allFiltersArrayOfStrings={allFiltersArrayOfStrings}
        setAllFiltersArrayOfStrings={setAllFiltersArrayOfStrings}
        allFiltersArrayOfObjects={allFiltersArrayOfObjects}
        setAllFiltersArrayOfObjects={setAllFiltersArrayOfObjects}
        reloadData={reloadData}
        filteringColumns={columnPropertyList
          .filter((item) => item.isFilterable)
          .map((item) => item.column)}
        rowsPerPage={rowsPerPage}
      />
    );
  }

  function getAllFiltersAppliedToShow() {
    return (
      <AllFiltersApplied
        orderBy={orderBy}
        property={property}
        allFiltersArrayOfStrings={allFiltersArrayOfStrings}
        setAllFiltersArrayOfStrings={setAllFiltersArrayOfStrings}
        allFiltersArrayOfObjects={allFiltersArrayOfObjects}
        setAllFiltersArrayOfObjects={setAllFiltersArrayOfObjects}
        reloadData={reloadData}
        rowsPerPage={rowsPerPage}
      />
    );
  }

  function getCreateButton() {
    return (
      <Button
        data-testid="createPrimary"
        className="mb-3"
        onClick={(event) => handleUpsert(event, null)}
        endIcon={<CreateIcon />}
        variant="outlined"
        color="success"
      >
        Create
      </Button>
    );
  }

  function setPageHeading(heading) {
    return <h2 style={{ paddingRight: "200px" }}>{heading}</h2>;
  }
  const getEntriesInTable = () => {
    const arr = [];

    function getActionColumnContent(config) {
      return (
        <TableCell>
          <Button
            className="mb-3"
            variant="outlined"
            color="warning"
            style={{ minWidth: "81px" }}
            size="sm"
            onClick={(event) => handleUpsert(event, config)}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="sm"
            onClick={(event) =>
              showDeleteConfirmationPopUp(event, config["id"])
            }
          >
            {heading === "Application Configuration" ? "Revert" : "Delete"}
          </Button>
        </TableCell>
      );
    }

    for (const index in existingConfig) {
      const config = existingConfig[index];
      arr.push(
        <TableRow id={config["id"]} key={config["id"]}>
          {columnPropertyList.map((item) => {
            if (item.label === "Actions") return getActionColumnContent(config);
            return (
              <TableCell>
                {config[item.column] === null ||
                config[item.column] === undefined
                  ? null
                  : config[item.column].toString()}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
    return arr;
  };
  const getSortableColumnHeader = () => {
    const list = [];
    columnPropertyList.forEach((item) => {
      list.push(
        <SortableTable
          label={item.label}
          column={item.column}
          setOrderBy={setOrderBy}
          property={property}
          setProperty={setProperty}
          minWidth={item.minWidth}
          propertyOrderBy={orderBy}
          allFiltersArrayOfObjects={allFiltersArrayOfObjects}
          reloadData={reloadData}
          setCurrentPage={reloadData}
          isSortable={item.isSortable}
          rowsPerPage={rowsPerPage}
        />
      );
    });
    return list;
  };
  const createTable = () => {
    return (
      <Table
        responsive
        striped
        bordered
        hover
        style={{ backgroundColor: "white" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>{getSortableColumnHeader()}</TableRow>
        </TableHead>
        <TableBody>{getEntriesInTable()}</TableBody>
      </Table>
    );
  };
  function getSortablePaginatedAndFilterableTable(heading) {
    return (
      <Container style={{ paddingTop: "110px", paddingLeft: "150px" }}>
        <Row style={{ minWidth: "119%" }}>
          <Col xl="2">{getFilterDropDownUtil()}</Col>
          <Col xl="9">{setPageHeading(heading)}</Col>
          <Col lg="1">{getCreateButton(handleUpsert)}</Col>
        </Row>
        {getAllFiltersAppliedToShow()}
        {createTable()}
        <Row className="justify-content-center">
          {!isSearchTemplateIdLoading && (
            <Col md={10}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            </Col>
          )}
        </Row>
        {getPagination(totalPages, currentPage, handlePagination)}
        {getUpsertPopup()}
        {showDeleteConfirmationPopUp(null, null)}
        {getSuccess()}
        {getError()}
      </Container>
    );
  }
  return {
    getSortablePaginatedAndFilterableTable,
    orderBy,
    reloadData,
  };
}
