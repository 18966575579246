import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import MessageIcon from "@mui/icons-material/Message";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { TextField } from "@mui/material";
import { searchMessageStatus } from "../Api/NotificationStatusApi";
import Spinner from "react-bootstrap/Spinner";
import CollapsibleTable from "../Components/Result";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import dayjs from "dayjs";

function addMinutesToTimestamp(timestamp, minutes) {
  const dateObj = new Date(timestamp);
  dateObj.setMinutes(dateObj.getMinutes() + minutes);
  return dateObj;
}

function subtractMinutesFromTimestamp(timestamp, minutes) {
  const dateObj = new Date(timestamp);
  dateObj.setMinutes(dateObj.getMinutes() - minutes);
  return dateObj;
}

function SearchNotificationStatus() {
  const [traceId, setTraceId] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [receiver, setReceiver] = useState("");
  const [elasticSearchServiceName, setElasticSearchServiceName] = useState("h");
  const [environment, setEnvironment] = useState("prod");
  const [channel, setChannel] = useState("sms");
  const [value, setValue] = useState("sms");
  const [showLoader, setShowLoader] = useState(false);
  const [resultToShow, setResultToShow] = useState({});

  // query params
  const queryParams = new URLSearchParams(window.location.search);
  const timestampQueryParam = queryParams.get("timestamp");
  const trackingIdQueryParam = queryParams.get("trackingId");
  const carrierQueryParam = queryParams.get("carrier");

  const handleTabChange = (e, newValue) => {
    setValue(newValue);
    setChannel(newValue);
    setReceiver("");
  };
  const handleEnvironmentChange = (e, newValue) => {
    setEnvironment(newValue);
  };
  const handleElasticSearchServiceChange = (e, newValue) => {
    setElasticSearchServiceName(newValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    processResponse({
      traceId,
      receiver,
      trackingId,
      serviceName: elasticSearchServiceName,
      environment,
      channel,
      startTime: convertISTtoUTC(startTime),
      endTime: convertISTtoUTC(endTime),
    });
  };
  const processResponse = async ({
    traceId,
    trackingId,
    receiver,
    serviceName,
    environment,
    channel,
    startTime,
    endTime,
  }) => {
    function getTraceIdSet(response) {
      const set = new Set();
      if (response.data.response != null) {
        response.data.response.forEach((element) => {
          set.add(element["traceId"]);
        });
      }
      if (response.data.error != null) {
        response.data.error.forEach((element) => {
          set.add(element["traceId"]);
        });
      }
      return set;
    }
    function getResponseListMappedWithTraceIds(traceIdSet, response) {
      var processedResponse = {};
      const errors = [];
      traceIdSet.forEach((traceId) => {
        response.error.forEach((error) => {
          if (error.traceId === traceId) {
            errors.push(error);
          }
        });
        const attempts = [];
        response.attempt.forEach((attempt) => {
          if (attempt.traceId === traceId) {
            attempts.push(attempt);
          }
        });
        attempts.sort((a, b) => {
          const lastCharA = a.attemptMessage.charAt(
            a.attemptMessage.length - 1
          );
          const lastCharB = b.attemptMessage.charAt(
            b.attemptMessage.length - 1
          );
          if (lastCharA < lastCharB) {
            return -1;
          }
          if (lastCharA > lastCharB) {
            return 1;
          }
          return 0;
        });

        const responses = [];
        const uniqueIdSet = new Set();
        response.response.forEach((response) => {
          if (response.traceId === traceId) {
            responses.push(response);
            uniqueIdSet.add(response.uniqueId);
          }
        });
        const callbacks = [];
        response.callback.forEach((callback) => {
          if (uniqueIdSet.has(callback.uniqueId)) {
            callbacks.push(callback);
          }
        });
        const traceIdResponse = {};
        traceIdResponse["errors"] = errors;
        traceIdResponse["attempts"] = attempts;
        traceIdResponse["responses"] = responses;
        traceIdResponse["callbacks"] = callbacks;
        processedResponse[traceId] = traceIdResponse;
      });
      processedResponse = Object.fromEntries(
        Object.entries(processedResponse).sort()
      );
      console.log(processedResponse);
      setResultToShow(processedResponse);
    }
    try {
      setShowLoader(true);
      const response = await searchMessageStatus({
        traceId,
        receiver,
        trackingId,
        serviceName,
        environment,
        channel,
        startTime,
        endTime,
      });
      setShowLoader(false);
      const set = getTraceIdSet(response);
      getResponseListMappedWithTraceIds(set, response.data);
    } catch (error) {
      setShowLoader(false);
    }
  };

  const handleChange = (formVariable, event) => {
    switch (formVariable) {
      case "traceId":
        setTraceId(event.target.value);
        break;
      case "startTime":
        setStartTime(dayjs(event));
        break;
      case "endTime":
        setEndTime(dayjs(event));
        break;
      case "trackingId":
        setTrackingId(event.target.value);
        break;
      case "receiver":
        setReceiver(event.target.value);
        break;
      default:
        break;
    }
  };

  const convertISTtoUTC = (time) => {
    return dayjs(time).toISOString();
  };

  useEffect(() => {
    const defaultDate = new Date();
    //GMT + 4.5hr = IST - 1hr
    defaultDate.setTime(defaultDate.getTime() + 4.5 * 60 * 60 * 1000);
    setStartTime(defaultDate.toISOString().slice(0, 19));
    //GMT + 4.5hr + 1hr = IST
    defaultDate.setTime(defaultDate.getTime() + 60 * 60 * 1000);
    setEndTime(defaultDate.toISOString().slice(0, 19));
  }, []);

  useEffect(() => {
    if (
      timestampQueryParam &&
      timestampQueryParam !== null &&
      timestampQueryParam !== ""
    ) {
      setStartTime(subtractMinutesFromTimestamp(timestampQueryParam, 20));
      setEndTime(addMinutesToTimestamp(timestampQueryParam, 20));
    }
    if (
      trackingIdQueryParam &&
      trackingIdQueryParam !== null &&
      trackingIdQueryParam !== ""
    ) {
      setTraceId(trackingIdQueryParam);
    }
    if (
      carrierQueryParam &&
      carrierQueryParam !== null &&
      carrierQueryParam !== ""
    ) {
      setValue(carrierQueryParam);
      setChannel(carrierQueryParam);
    }
  }, [carrierQueryParam, trackingIdQueryParam, timestampQueryParam]);

  return (
    <>
      <Container style={{ paddingTop: "50px", paddingLeft: "240px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="icon label tabs example"
          >
            <Tab
              data-testid="sms-tab"
              icon={<MessageIcon />}
              label="SMS"
              value={"sms"}
              sx={{ color: "primary.light" }}
            />
            <Tab
              data-testid="email-tab"
              icon={<EmailIcon />}
              label="EMAIL"
              value={"email"}
              sx={{ color: "primary.light" }}
            />
            <Tab
              data-testid="whatsapp-tab"
              icon={<WhatsAppIcon />}
              label="WHATSAPP"
              value={"whatsapp"}
              sx={{ color: "primary.light" }}
            />
            <Tab
              data-testid="ivr-tab"
              icon={<CallIcon />}
              label="IVR"
              value={"ivr"}
              sx={{ color: "primary.light" }}
            />
          </Tabs>
        </div>
      </Container>
      <Divider sx={{ height: "2px", backgroundColor: "black" }}></Divider>
      <Container style={{ paddingTop: "25px", paddingLeft: "90px" }}>
        <Form onSubmit={handleSubmit}>
          <fieldset>
            <Row className="my-2">
              <Col md={1}></Col>
              <Col md={3} style={{ marginTop: "5px" }}>
                <TextField
                  id="receiver"
                  inputProps={{ "data-testid": "receiver" }}
                  placeholder={channel === "email" ? "Email" : "Phone Number"}
                  value={receiver}
                  onChange={(e) => handleChange("receiver", e)}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col md={1}></Col>
              <Col md={3} style={{ marginTop: "6px", marginLeft: "-30px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDateTimePicker
                    data-testid="startTime"
                    label="Start Time"
                    value={dayjs(startTime)}
                    ampm={false}
                    onChange={(e) => handleChange("startTime", e)}
                  />
                </LocalizationProvider>
              </Col>
              <Col md={3} style={{ marginTop: "6px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDateTimePicker
                    data-testid="endTime"
                    label="End Time"
                    value={dayjs(endTime)}
                    ampm={false}
                    onChange={(e) => handleChange("endTime", e)}
                  />
                </LocalizationProvider>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={1}></Col>
              <Col md={3}>
                <TextField
                  id="trackingId"
                  inputProps={{ "data-testid": "trackingId" }}
                  placeholder="Tracking ID"
                  value={trackingId}
                  onChange={(e) => handleChange("trackingId", e)}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  variant="outlined"
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    width: "100%",
                  }}
                />
              </Col>
              <Col md={1}></Col>
              <Col md={1} style={{ paddingTop: "18px" }}>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  style={{ color: "#000000DE", fontSize: "17px" }}
                >
                  Environment
                </FormLabel>
              </Col>
              <Col md={4} style={{ paddingTop: "11px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleEnvironmentChange}
                  defaultValue={"prod"}
                >
                  <FormControlLabel
                    value="prod"
                    style={{ color: "grey", fontSize: "17px" }}
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="Production"
                  />
                  <FormControlLabel
                    value="stage"
                    style={{ color: "grey", fontSize: "17px" }}
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="Staging"
                  />
                </RadioGroup>
              </Col>
            </Row>
            <Row className={"my-2"}>
              <Col md={1}></Col>
              <Col md={3}>
                <TextField
                  style={{ width: "100%" }}
                  id="traceId"
                  inputProps={{ "data-testid": "traceId" }}
                  placeholder="Trace ID"
                  value={traceId}
                  onChange={(e) => handleChange("traceId", e)}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  variant="outlined"
                />
              </Col>
              <Col md={1}></Col>
              <Col md={1} style={{ marginLeft: "-15px", paddingTop: "14px" }}>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  style={{ color: "#000000DE", fontSize: "17px" }}
                >
                  Service
                </FormLabel>
              </Col>
              <Col md={4} style={{ marginLeft: "14px", paddingTop: "7px" }}>
                <RadioGroup
                  row
                  aria-labelledby="Service"
                  name="row-radio-buttons-group"
                  onChange={handleElasticSearchServiceChange}
                  defaultValue={"h"}
                >
                  <FormControlLabel
                    id={"Service"}
                    value="h"
                    style={{ color: "grey" }}
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="OTP"
                  />
                  <FormControlLabel
                    id={"Service"}
                    sx={{ marginLeft: "35px" }}
                    style={{ color: "grey" }}
                    value="communication-platform"
                    control={<Radio sx={{ color: "primary.light" }} />}
                    label="Promotional/Transactional"
                  />
                </RadioGroup>
              </Col>
            </Row>
            <Row className={"my-2"}>
              <Col md={1}></Col>
              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Text
                  id="ownerFormat"
                  muted
                  style={{ paddingRight: "34%", fontFamily: "Roboto" }}
                >
                  Note: Enter atleast 1 of the 3 to begin the search
                </Form.Text>
              </Col>
            </Row>
            <Row className={"my-3"}>
              <Col md={1}></Col>
              <Col md={1}>
                <Button
                  type="Submit"
                  variant={"contained"}
                  onClick={handleSubmit}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </fieldset>
        </Form>
      </Container>
      <Row>
        <Col md={3}></Col>
        <Col md={8}>
          {showLoader ? (
            <Spinner
              className="my-4"
              align="center"
              animation="border"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <CollapsibleTable result={resultToShow}></CollapsibleTable>
          )}
        </Col>
      </Row>
    </>
  );
}

export default SearchNotificationStatus;
