import React, { useState, useRef, useEffect } from "react";
import fileDrop from "../assets/svg/export.svg";
import exclamation from "../assets/svg/exclamationMark.svg";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

function BulkCSVUpload({ BulkCSVHandler, BulkCSVUploadHandler }) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const csvFileMaxSize = process.env.REACT_APP_CSVFILE_MAX_SIZE_IN_MB;

  const handleFileDrop = async (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length !== 1) {
      setError("Please drop a single file.");
    } else if (files[0].type !== "text/csv") {
      setError("Please drop a CSV file.");
    } else if (files[0].size > 1024 * 1024 * csvFileMaxSize) {
      setError(`File size must be less than ${csvFileMaxSize}MB`);
    } else {
      setFile(files[0]);
    }
  };

  const inputRef = useRef(null);

  const handleFileChange = () => {
    const files = inputRef.current.files[0];
    if (files.type !== "text/csv") {
      setError("Please drop a CSV file.");
    } else if (files.size > 1024 * 1024 * csvFileMaxSize) {
      setError(`File size must be less than ${csvFileMaxSize}MB`);
    } else {
      setFile(files);
    }
  };

  const handleFileDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileDragLeave = (event) => {
    event.preventDefault();
    setError(null);
  };

  const processData = (csvData) => {
    const rows = csvData.split("\n");
    const header = rows[0].split(",").map((item) => item.trim());

    const data = [];
    for (let i = 1; i < rows.length; i++) {
      const rowData = rows[i].split(",");
      if (rowData.length === header.length) {
        const entry = {};
        for (let j = 0; j < header.length; j++) {
          const value = rowData[j].trim(); // Trim whitespace at both ends
          entry[header[j]] = value;
        }
        data.push(entry);
      }
    }
    // Pass the data to the form component or perform further processing
    BulkCSVUploadHandler(data);
  };

  const handleCreateUsers = async () => {
    setError(null);
    if (!file) {
      setError("Invalid File!!");
      return;
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      processData(csvData);
    };

    reader.readAsText(file);
    BulkCSVHandler();
  };

  useEffect(() => {
    if (error !== null) {
      const timeout = setTimeout(() => {
        setError(null);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [error]);

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black inset-0 bg-opacity-50 flex items-center z-10 justify-center backdrop-blur-sm">
      <div className="absolute w-auto h-auto min-h-[50%] min-w-[50%] bg-white rounded-lg p-10 flex flex-col">
        <h2 className="font-[600] text-[20px] text-[#666666] leading-6">
          Bulk CSV Upload
        </h2>
        <div className="absolute top-6 right-6 ">
          <CloseIcon
            data-testid="close-icon"
            onClick={BulkCSVHandler}
            fontSize="small"
            className="hover:cursor-pointer  hover:text-text_darkGrey p-1 bg-text_lighterGrey rounded-xl text-text_grey"
          />{" "}
        </div>
        {!file ? (
          <div>
            <div className="pt-10 items-center flex flex-col">
              <h1 className="text-[#333333] text-[20px] leading-7 ">
                Make sure you are using template file
              </h1>

              <h2 className="text-[#333333] text-[20px] leading-7 ">
                Dont have a template file?
              </h2>

              <h1 className="text-[#333333] text-[14px] leading-5">
                <a
                  href="https://drive.google.com/uc?id=1urTNAf2qQ-lSqp_ILg3JCq5HCfRVA_Tr&export=download"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary font-semibold"
                >
                  Download here
                </a>{" "}
                (format with phone number)
              </h1>
              <h1 className="text-[#333333] text-[14px] leading-5">
                <a
                  href="https://drive.google.com/uc?id=1kYL6ljfsq0cS7Rl9k8DWCFoJJYnJjvg3&export=download"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary font-semibold"
                >
                  Download here
                </a>{" "}
                (format with email Id)
              </h1>
              <h1 className="text-[#333333] text-[14px] leading-5">
                <a
                  href="https://drive.google.com/uc?id=1LoZw6IwC3ik29UCWqkXnuucK866Qisk2&export=download"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary font-semibold"
                >
                  Download here
                </a>{" "}
                (format with premium Id)
              </h1>
              <h1 className="text-[#333333] text-[14px] leading-5">
                <a
                  href="https://drive.google.com/uc?id=1QryhVOEVoWeSQx7he_rgIGRyg6BRzvQZ&export=download"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary font-semibold"
                >
                  Download here
                </a>{" "}
                (format with free user Id)
              </h1>
            </div>
            <div
              data-testid="file-drop-zone"
              className={`file-drop-zone${
                error ? " error" : ""
              } w-auto h-auto border-[1px] border-primary border-dashed my-10 p-10 flex flex-col justify-center items-center rounded-lg mb-[100px]`}
              onDrop={handleFileDrop}
              onDragOver={handleFileDragOver}
              onDragLeave={handleFileDragLeave}
              onClick={() => inputRef.current.click()}
            >
              <input
                data-testid="file-input"
                type="file"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={(event) => handleFileChange(event.target.files)}
              />
              <img src={fileDrop} alt="" className="max-w-[112px] mb-6" />
              <p className="text-[#666666] text-[16px] leading-6">
                Drag a CSV File or click to select a file (max {csvFileMaxSize}
                MB)
              </p>
              {error && (
                <div className="fixed bottom-10 z-10 bg-[#2E373E] p-5 flex flex-row rounded-lg">
                  <img src={exclamation} alt="" />
                  <p className="mx-3 text-white ">{error}</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="mb-[100px]">
              <div className="w-auto h-auto border-[1px] border-primary border-dashed my-10 p-4 flex flex-row justify-between items-center rounded-lg ">
                <div>
                  {(file.size / 1024).toFixed(2)} KB{" "}
                  <InsertDriveFileIcon fontSize="large" />
                  <span>{file.name}</span>
                </div>
                <Button color="secondary" onClick={() => setFile(null)}>
                  <CloseIcon fontSize="small" className="text-[#c4c4c4] ml-1" />
                </Button>
              </div>
            </div>
          </div>
        )}

        <button
          onClick={handleCreateUsers}
          disabled={!file}
          className={
            file
              ? "absolute bottom-10 left-10 px-10 py-3 bg-[#823598] rounded text-white"
              : "absolute bottom-10 left-10 px-10 py-3 bg-[#83359835] rounded text-white"
          }
        >
          Upload
        </button>

        <button
          onClick={BulkCSVHandler}
          className="absolute bottom-10 left-[190px] px-10 py-3 bg-[#F2F2F2] rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default BulkCSVUpload;
