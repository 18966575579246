import axios from "axios";

const previewTemplate = async (templateText, templateParams) => {
  const url =
    process.env.REACT_APP_BASE_URL + process.env.REACT_APP_TEMPLATE_PREVIEW_URL;
  const config = {
    withCredentials: true,
    headers: {
      "content-type": "application/json",
    },
  };

  const body = {
    templateText: templateText,
    templateParams: JSON.parse(templateParams),
  };

  return await axios.post(url, body, config).then(
    (response) => {
      return response;
    },
    (error) => {
      return {
        data: "Error: " + (error === undefined ? "Undefined" : error.message),
      };
    }
  );
};

export { previewTemplate };
