import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";
const theme = createTheme({
  typography: {
    fontFamily: "Roboto",
  },
  palette: {
    primary: {
      main: "#823598",
      light: "#9d48b750",
      dark: "#791994",
    },
    secondary: {
      main: "#dd5ef5",
      light: "#e68bfa",
      dark: "#d53ef6",
    },
    text: {
      secondary: "#ffffff",
      primary: "#000000",
    },
    success: {
      main: green[500],
    },
  },
});
export default theme;
