import React from "react";
import { useEffect, useRef } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  getAllConfig,
  createConfig,
  deleteConfig,
  updateConfig,
} from "../Api/CrudApi";
import { useCrud } from "../Utils/Utils";
import { APPLICATION_CONFIGURATION_COLUMN_PROPERTIES_LIST } from "../Utils/constants";

function ApplicationConfiguration() {
  const initialPageLoad = useRef(false);
  const heading = "Application Configuration";
  function getConfigObject(selectedObject) {
    return {
      key: selectedObject["key"],
      value: selectedObject["value"],
      version: selectedObject["version"],
    };
  }
  function getModalBody(
    handleChange,
    selectedObject,
    getTimeDifferenceFromNow,
    isCreate
  ) {
    return (
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Key</Form.Label>
          {isCreate ? (
            <Form.Control
              data-testid="key"
              placeholder="Key of the configuration"
              onChange={(e) => handleChange("key", e.target.value)}
              autoFocus
              value={selectedObject === undefined ? "" : selectedObject["key"]}
            />
          ) : (
            <Form.Control
              data-testid="key"
              placeholder="Key of the configuration"
              onChange={(e) => handleChange("key", e.target.value)}
              autoFocus
              value={selectedObject === undefined ? "" : selectedObject["key"]}
              disabled
            />
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>Value</strong>
          </Form.Label>
          <Form.Control
            data-testid="value"
            placeholder="Value of the configuration"
            onChange={(e) => handleChange("value", e.target.value)}
            autoFocus
            value={selectedObject === undefined ? "" : selectedObject["value"]}
            required
          />
        </Form.Group>
      </Modal.Body>
    );
  }
  const url =
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_APPLICATION_CONFIGURATION_URL;
  const { getSortablePaginatedAndFilterableTable, orderBy, reloadData } =
    useCrud(
      heading,
      APPLICATION_CONFIGURATION_COLUMN_PROPERTIES_LIST,
      getAllConfig,
      updateConfig,
      deleteConfig,
      createConfig,
      getModalBody,
      getConfigObject,
      "key",
      url
    );

  useEffect(() => {
    const fetchData = async () => {
      if (!initialPageLoad.current) {
        initialPageLoad.current = true;
        await reloadData(0, orderBy, "key", [], "10");
      }
    };

    fetchData().catch(console.error);
  });
  return <>{getSortablePaginatedAndFilterableTable(heading)}</>;
}

export default ApplicationConfiguration;
