import React from "react";
import Cookies from "universal-cookie";
import { properties } from "../../properties";
import { Navigate } from "react-router-dom";

const cookies = new Cookies();

export default function AdminProtectedRoute({ component }) {
  if (
    !cookies.get(properties.GOOGLE_USER_DETAILS_COOKIE) ||
    cookies.get(properties.GOOGLE_IS_ADMIN_STATUS) === "false"
  ) {
    return <Navigate replace to="/" />;
  } else {
    return component;
  }
}
