import React from "react";
const Home = () => {
  return (
    <>
      <h1 style={{ fontSize: "5em", padding: "117px", fontFamily: "initial" }}>
        {" "}
        Welcome to Core Platform UI{" "}
      </h1>
      <style>
        {`
            body {
                background-color: #ffffff;
            }
            `}
      </style>
    </>
  );
};

export default Home;
