import { createTheme } from "@mui/material/styles";

export const copyToClipboard = async (textToCopy) => {
  await navigator.clipboard.writeText(textToCopy);
};

export const customTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#823598",
        },
      },
    },
  },
});

export function formatDatetime(inputDatetime) {
  try {
    const parsedDatetime = new Date(inputDatetime);

    // Check if the parsing was successful
    if (isNaN(parsedDatetime.getTime())) {
      return "Invalid datetime format";
    }

    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDatetime = parsedDatetime.toLocaleString("en-US", options);
    return formattedDatetime;
  } catch (error) {
    return "Invalid datetime format";
  }
}
