import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ArticleIcon from "@mui/icons-material/Article";
import SlackIcon from "../../assets/svg/sidebar/slack.svg";
import {
  menuBar,
  getNameFromRoute,
  getRouteFromName,
} from "../../assets/data/menuBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";

const drawerWidth = 270;

function SideBar() {
  const url = useLocation();
  const navigate = useNavigate();
  const [force, setForce] = useState(1);
  const [collapseState, setCollapseState] = useState({});
  const [selectedIndex, setSelectedIndex] = React.useState(
    url.pathname === "/searchNotificationStatus"
      ? "searchNotificationStatus"
      : ""
  );
  useEffect(() => {
    const stateArray = {};
    menuBar.forEach((item) => {
      stateArray[item.text] = true;
    });
    setSelectedIndex(getNameFromRoute(window.location.pathname.slice(1)));
    setCollapseState((collapseState) => stateArray);
  }, []);
  const onclickMenuItem = (event) => {
    const stateArray = collapseState;
    for (var key in stateArray) {
      if (stateArray.hasOwnProperty(key) && key === event.target.innerText) {
        if (event.type === "mouseenter") stateArray[key] = true;
        else stateArray[key] = !stateArray[key];
      }
    }
    setCollapseState((collapseState) => stateArray);
    console.log(force);
    setForce((force) => force + 1);
  };

  const handleListItemClick = (event, text) => {
    onclickMenuItem(event);
    if (text !== "Template" && text !== "Control Panel") {
      setSelectedIndex(text);
      navigate(getRouteFromName(text));
    }
  };

  const StyledList = styled(List)(({ theme }) => ({
    // selected and (selected + hover) states
    "&& .Mui-selected, && .Mui-selected:hover": {
      backgroundColor: "white",
      "&, & .MuiListItemIcon-root": {
        color: theme.palette.primary.main,
      },
      "&, & .MuiTypography-root": {
        color: theme.palette.primary.main,
      },
    },
    // hover states
    "& .MuiListItemButton-root:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  }));
  const MyListItemButton = styled(ListItemButton)(({ theme }) => ({
    "&:hover": {
      transform: "scale(1.1)",
    },
  }));
  return (
    <>
      <Box sx={{ display: "flex", bgcolor: "error.main" }}>
        <CssBaseline />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              bgcolor: "primary.main",
              height: "84vh",
            },
          }}
          variant="permanent"
          anchor="top"
        >
          <div style={{ padding: "4px", marginTop: "10px" }}>
            <h2 style={{ color: "white" }}>BYJU'S</h2>
          </div>
          <StyledList key={"key"}>
            {menuBar.map((item, index) => (
              <div key={index}>
                <ListItem key={item.text} id={item.text} disablePadding>
                  <MyListItemButton
                    selected={selectedIndex === item.text}
                    onClick={(event) => handleListItemClick(event, item.text)}
                    key={item.text + 1}
                    sx={{
                      backgroundColor: "primary.light",
                      marginTop: "30px",
                    }}
                    data-testid={item.text}
                  >
                    <ListItemIcon
                      key={item.text + 2}
                      sx={{ color: "text.secondary" }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      key={item.text + 3}
                      primaryTypographyProps={{ color: "text.secondary" }}
                    />
                    {item.subList.length > 0 ? (
                      collapseState[item.text] ? (
                        <ExpandMore
                          key={item.text + 4}
                          sx={{ color: "text.secondary" }}
                        />
                      ) : (
                        <ExpandLess
                          key={item.text + 4}
                          sx={{ color: "text.secondary" }}
                        />
                      )
                    ) : null}
                  </MyListItemButton>
                </ListItem>
                <Divider key={item.text + 5} />
                {item.subList.length > 0 ? (
                  <Collapse
                    in={collapseState[item.text]}
                    key={item.text + 6}
                    id={item.text}
                    timeout="auto"
                    unmountOnExit
                    onClick={onclickMenuItem}
                  >
                    <StyledList>
                      {item.subList.map((subItem, index) => (
                        <ListItem
                          sx={{ pl: 2 }}
                          key={subItem.text}
                          disablePadding
                        >
                          <MyListItemButton
                            selected={selectedIndex === subItem.text}
                            onClick={(event) =>
                              handleListItemClick(event, subItem.text)
                            }
                            key={subItem.text + 1}
                            sx={{ marginTop: "10px" }}
                          >
                            <ListItemIcon
                              key={subItem.text + 2}
                              sx={{ color: "text.secondary" }}
                            >
                              {subItem.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={subItem.text}
                              key={subItem.text + 3}
                              primaryTypographyProps={{
                                color: "text.secondary",
                              }}
                            />
                          </MyListItemButton>
                          <Divider key={subItem.text + 4} />
                        </ListItem>
                      ))}
                    </StyledList>
                  </Collapse>
                ) : null}
              </div>
            ))}
          </StyledList>
        </Drawer>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              bgcolor: "primary.main",
              height: "16vh",
            },
          }}
          variant="permanent"
          anchor="bottom"
        >
          <StyledList>
            <ListItem key={"DocumentationItem"}>
              <MyListItemButton
                component="a"
                href="https://wiki.byjus.onl/en/Communication-Platform-3a7792b9655a43efba4c22c58a31ad3a"
                target="_blank"
                rel="noopener"
                sx={{
                  position: "fixed",
                  bottom: "2%",
                  backgroundColor: "primary.light",
                  marginTop: "1px",
                  width: "236px",
                }}
                key={"DocumentationButton"}
              >
                <ListItemIcon
                  sx={{ color: "text.secondary" }}
                  key={"DocumentationIcon"}
                >
                  <ArticleIcon key={"Documentation"} />
                </ListItemIcon>
                <ListItemText
                  primary={"Documentation"}
                  primaryTypographyProps={{ color: "text.secondary" }}
                  key={"DocumentationText"}
                />
              </MyListItemButton>
            </ListItem>
            <ListItem key={"HelpItem"}>
              <MyListItemButton
                component="a"
                href={"https://byjus.slack.com/archives/notifications-support"}
                target="_blank"
                rel="noopener"
                sx={{
                  backgroundColor: "primary.light",
                  position: "fixed",
                  bottom: "9%",
                  marginBottom: "1px",
                  width: "236px",
                }}
                key={"HelpButton"}
              >
                <ListItemIcon sx={{ color: "text.secondary" }} key={"HelpIcon"}>
                  <img src={SlackIcon} alt="Slack Icon" />
                </ListItemIcon>
                <ListItemText
                  primary={"Slack"}
                  primaryTypographyProps={{ color: "text.secondary" }}
                  key={"HelpText"}
                />
              </MyListItemButton>
            </ListItem>
          </StyledList>
        </Drawer>
      </Box>
    </>
  );
}

export default SideBar;
