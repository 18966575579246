import React from "react";
import { Button } from "@mui/material";
import TableCell from "@mui/material/TableCell";

const SortableTable = ({
  label,
  column,
  setOrderBy,
  property,
  setProperty,
  minWidth,
  propertyOrderBy,
  allFiltersArrayOfObjects,
  reloadData,
  setCurrentPage,
  isSortable,
  rowsPerPage,
}) => {
  const handleSort = async (order) => {
    setOrderBy(order);
    setProperty(column);
    setCurrentPage(1);
    await reloadData(0, order, column, allFiltersArrayOfObjects, rowsPerPage);
  };

  return (
    <TableCell align="left" style={{ minWidth: minWidth }}>
      <strong>{label}</strong>
      {isSortable && (
        <Button
          size="large"
          variant="text"
          onClick={(event) =>
            handleSort(propertyOrderBy === "ASC" ? "DESC" : "ASC")
          }
          style={{ minWidth: "24px" }}
        >
          {column !== property
            ? "⋮"
            : column === property && propertyOrderBy === "ASC"
            ? "↑"
            : "↓"}
        </Button>
      )}
    </TableCell>
  );
};

export default SortableTable;
