import axios from "axios";

const sendNotification = async ({
  request,
  serviceProvider,
  environment,
  serviceName,
}) => {
  let url;
  if (environment === "stage") {
    if (serviceName === "otp") {
      url =
        serviceProvider.toString() === ""
          ? process.env.REACT_APP_SEND_NOTIFICATION_STAGE_URL
          : process.env.REACT_APP_SEND_TEST_OTP_STAGE_URL;
    } else {
      url = process.env.REACT_APP_SEND_NOTIFICATION_STAGE_URL;
    }
  } else {
    if (serviceName === "otp") {
      url =
        serviceProvider.toString() === ""
          ? process.env.REACT_APP_SEND_NOTIFICATION_PROD_URL
          : process.env.REACT_APP_SEND_TEST_OTP_PROD_URL;
    } else {
      url = process.env.REACT_APP_SEND_NOTIFICATION_PROD_URL;
    }
  }

  const testOtpConfig = {
    withCredentials: true,
    headers: {
      "content-type": "application/json",
    },
    params: {
      serviceProviders: serviceProvider.toString(),
    },
  };

  const otpConfig = {
    withCredentials: true,
    headers: {
      "content-type": "application/json",
    },
    params: {
      category: serviceName,
      environment: environment,
    },
  };

  try {
    if (serviceName === "otp" && serviceProvider.toString() !== "") {
      return await axios.post(url, request, testOtpConfig);
    } else {
      return await axios.post(url, request, otpConfig);
    }
  } catch (e) {
    console.log(e);
  }
};

export { sendNotification };
