import React, { useEffect, useRef } from "react";

import { useCrud } from "../Utils/Utils";
import { RATE_LIMIT_COLUMN_PROPERTIES_LIST } from "../Utils/constants";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {
  getAllConfig,
  createConfig,
  deleteConfig,
  updateConfig,
} from "../Api/CrudApi";

function RateLimitConfig() {
  const initialPageLoad = useRef(false);
  const heading = "Rate Limit Configs";
  function getConfigObject(selectedObject) {
    return {
      id: selectedObject["id"],
      config: selectedObject["config"],
      maxRequests: selectedObject["maxRequests"],
      maxRequestsToBlock: selectedObject["maxRequestsToBlock"],
      rateDurationInSeconds: selectedObject["rateDurationInSeconds"],
      lockDurationInSeconds: selectedObject["lockDurationInSeconds"],
      owners: selectedObject["owners"],
      startTime: selectedObject["startTime"],
      endTime: selectedObject["endTime"],
    };
  }
  function getModalBody(
    handleChange,
    selectedObject,
    getTimeDifferenceFromNow
  ) {
    return (
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>Config</strong>
          </Form.Label>
          <Form.Control
            type="text"
            data-testid="config"
            placeholder="config name"
            onChange={(e) => handleChange("config", e.target.value)}
            autoFocus
            value={selectedObject === undefined ? "" : selectedObject["config"]}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <strong>MaxRequests</strong>
          </Form.Label>
          <Form.Control
            data-testid="maxRequests"
            type="number"
            pattern="[0-9]+"
            placeholder="200"
            onChange={(e) =>
              handleChange("maxRequests", parseInt(e.target.value))
            }
            value={
              selectedObject === undefined ? "" : selectedObject["maxRequests"]
            }
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <strong>MaxRequestsToBlock</strong>
          </Form.Label>
          <Form.Control
            data-testid="maxRequestsToBlock"
            type="number"
            pattern="[0-9]+"
            placeholder="500"
            onChange={(e) =>
              handleChange("maxRequestsToBlock", parseInt(e.target.value))
            }
            value={
              selectedObject === undefined
                ? ""
                : selectedObject["maxRequestsToBlock"]
            }
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <strong>RateDurationInSeconds</strong>
          </Form.Label>
          <Form.Control
            data-testid="rateDurationInSeconds"
            type="number"
            placeholder="86400"
            onChange={(e) =>
              handleChange("rateDurationInSeconds", parseInt(e.target.value))
            }
            value={
              selectedObject === undefined
                ? ""
                : selectedObject["rateDurationInSeconds"]
            }
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <strong>LockDurationInSeconds</strong>
          </Form.Label>
          <Form.Control
            data-testid="lockDurationInSeconds"
            type="number"
            placeholder="10800"
            onChange={(e) =>
              handleChange("lockDurationInSeconds", parseInt(e.target.value))
            }
            value={
              selectedObject === undefined
                ? ""
                : selectedObject["lockDurationInSeconds"]
            }
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <strong>Owners</strong>
          </Form.Label>
          <Form.Control
            data-testid="owners"
            type="email"
            multiple
            placeholder="comma seperated emails"
            onChange={(e) =>
              handleChange(
                "owners",
                e.target.value.split(",").map((item) => item.trim())
              )
            }
            value={selectedObject === undefined ? "" : selectedObject["owners"]}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <strong>Start Time</strong>
          </Form.Label>

          <div>
            <input
              data-testid="startTime"
              type="datetime-local"
              step="1"
              onChange={(e) =>
                handleChange(
                  "startTime",
                  getTimeDifferenceFromNow(e.target.value)
                )
              }
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>End Time</strong>
          </Form.Label>
          <div>
            <input
              data-testid="endTime"
              type="datetime-local"
              step="1"
              onChange={(e) =>
                handleChange(
                  "endTime",
                  getTimeDifferenceFromNow(e.target.value)
                )
              }
            />
          </div>
        </Form.Group>
      </Modal.Body>
    );
  }
  const url =
    process.env.REACT_APP_BASE_URL +
    process.env.REACT_APP_RATE_LIMIT_CONFIG_URL;

  const { getSortablePaginatedAndFilterableTable, orderBy, reloadData } =
    useCrud(
      heading,
      RATE_LIMIT_COLUMN_PROPERTIES_LIST,
      getAllConfig,
      updateConfig,
      deleteConfig,
      createConfig,
      getModalBody,
      getConfigObject,
      "config",
      url
    );

  useEffect(() => {
    const fetchData = async () => {
      if (!initialPageLoad.current) {
        initialPageLoad.current = true;
        await reloadData(0, orderBy, "config", [], "10");
      }
    };

    fetchData().catch(console.error);
  });
  return <>{getSortablePaginatedAndFilterableTable(heading)}</>;
}

export default RateLimitConfig;
